import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import { addEmployeeList_action, updateEmployeeList_action, employeeDetail_action, deleteEmployeeList_action, employeeListContact_action, employeeContactDetail_action, addEmployeeContact_action, updateEmployeeContact_action, employeeRolePlayedDelete_action, deleteEmployeeContact_action } from '../../../actions/master_action/employee_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { withRouter } from '../../../utils/withRouter';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Loader from '../../../utils/Loader'
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { designationActionId, managerId, departmentId, partnerId, PrefixActionId, genderId, bloodGroupId, maritalStatusId, prefixId, rolePlayedId, Master, userEngagementRoleActionId } from '../../../utils/constants'
import { emailValidator, contactNoValidator } from '../../../utils/Validator';
import Modal from "react-modal";
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';


const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class employeeAU extends Component {

    constructor(props) {
        super(props)
        this.state = {
            prefixId: '',
            prefixList: '',
            firstName: '',
            lastName: '',
            code: '',
            dateofBirth: '',
            joiningDate: "",
            anniversaryDate: '',

            leaveBal: '',
            creditLeaveBal: '',

            EngRoleID: "",
            egrnID: "",
            rolePlayedId: "",
            rolePlayedList: [],
            addedRolePlayedList: [],
            deleteRoleId: "",

            code: '',
            firstName: '',
            middleName: '',
            lastName: '',
            bloodGroupId: '',
            bloodGroupList: [],
            genderId: '',
            genderList: '',
            designationId: '',
            designationList: [],
            departmentId: '',
            departmentList: [],
            managerId: '',
            managerList: '',
            partnerId: '',
            partnerList: '',
            email: '',
            maritalStatusId: "",
            maritalStatusList: [],
            ratePerHour: "",
            resignDate: "",
            balanceLeave: "",
            balanceCreditLeave: "",
            leaveTaken: "",
            creditLeaveTaken: "",
            contactNo: "",
            accountNo: "",
            membershipID: "",
            is_add: false,
            is_delete: false,
            is_update: false,
            employeeId: '',
            employee: [],
            isLoading: false,
            isActive: 0,
            finTodateLstRcd: '',
            lastRecord: '',
            isAccessToView: true,
            isAnyCompleted: false,
            isApprover: false,
            isTimesheetMandatory: false,
            prefixList: [],

            // EmployeeContact
            isOpen: false,
            EmployeeContactList: [],
            employeeContactId: '',
            EmoployeeContactDeleteId: "",
            Tel1: "",
            Tel2: "",
            contactPerson: "",
            contDateofBirth: "",
            contBloodGroupID: "",
            contEmail: '',
            is_Contactadd: false,
            is_Contactupdate: false,
            ID: "",
            contactId: '',
            disableFlag: false,
            EngRoleID: '',
            activeTab: 'home',
            startDate: new Date("2014/02/08"),
            endDate: new Date("2014/02/10"),
            previouslyFocusedElement: null,
            totalAnualHours: '',
            // is_add: false,
            // is_update: false,
            userEngagementRoleId: '',
            userEngagementRoleList: ''
        }
        this.maxDate = new Date(); // Today's date
        this.maxDate.setFullYear(this.maxDate.getFullYear() - 18); // Subtract 18 years from today

        this.getFillList = this.getFillList.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getEmployeeDetails = this.getEmployeeDetails.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.EmployeeContactrecord = this.EmployeeContactrecord.bind(this)
        this.getEmployeeContactList = this.getEmployeeContactList.bind(this)
        this.handleAddEmployeeContact = this.handleAddEmployeeContact.bind(this)
        this.handleTogleUpdate = this.handleTogleUpdate.bind(this)
        this.handleAddEmployeeContact = this.handleAddEmployeeContact.bind(this)
        this.handleUpdateEmployeeContact = this.handleUpdateEmployeeContact.bind(this)
        this.onSelectEmployeeRolePlayed = this.onSelectEmployeeRolePlayed.bind(this)
        this.deleteEmployeeRoleplayed = this.deleteEmployeeRoleplayed.bind(this)
        this.toggleModaldelete = this.toggleModaldelete.bind(this)
        this.handleTabKeyPress = this.handleTabKeyPress.bind(this)
        this.handleInputClick = this.handleInputClick.bind(this)
    }

    // Employee details 
    componentDidMount = () => {
        this.getFillList(PrefixActionId, '')
        this.getFillList(designationActionId, '')
        this.getFillList(managerId, '')
        this.getFillList(departmentId, '')
        this.getFillList(partnerId, '')
        this.getFillList(genderId, '')
        this.getFillList(bloodGroupId, '')
        this.getFillList(maritalStatusId, '')
        this.getFillList(prefixId, '')
        this.getFillList(rolePlayedId, '')
        this.getFillList(userEngagementRoleActionId, '')

        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            employeeId: this.props.location.state != null && this.props.location.state.employeeId != null ? this.props.location.state.employeeId : '',
            isAccessToView: true,
        })
        this.props.location.state != null && this.props.location.state.is_update && this.getEmployeeDetails()
        setTimeout(() => {
            this.getEmployeeContactList()
        }, 500);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }




    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                // focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                // this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            //   clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            //   this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    // get Quality Reviewer Name List dropdown list 
    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {

                    if (value == PrefixActionId) {
                        this.setState({ prefixList: data.data, isLoading: false })
                    }
                    if (value == designationActionId) {
                        this.setState({ designationList: data.data, isLoading: false })

                    }
                    if (value == managerId) {
                        this.setState({ managerList: data.data, isLoading: false })

                    }
                    if (value == departmentId) {
                        this.setState({ departmentList: data.data, isLoading: false })

                    }
                    if (value == partnerId) {
                        this.setState({ partnerList: data.data, isLoading: false })

                    }
                    if (value == genderId) {
                        this.setState({ genderList: data.data, isLoading: false })

                    }
                    if (value == bloodGroupId) {
                        this.setState({ bloodGroupList: data.data, isLoading: false })

                    }
                    if (value == maritalStatusId) {
                        this.setState({ maritalStatusList: data.data, isLoading: false })
                    }
                    if (value == PrefixActionId) {
                        this.setState({ prefixList: data.data, isLoading: false })
                    }
                    if (value == rolePlayedId) {
                        this.setState({ rolePlayedList: data.data, isLoading: false })
                    }
                    if (value == userEngagementRoleActionId) {
                        this.setState({ userEngagementRoleList: data.data, isLoading: false })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    getEmployeeDetails() {
        const { rolePlayedList } = this.state
        // console.log("rolePlayedList###",rolePlayedList)
        var id = this.props.location.state.employeeId
        this.setState({ isLoading: true })
        this.props
            .employeeDetail_action(id)
            .then((data) => {
                console.log('rolePlayedList ++++ data.data', data.data['roleList'])
                if (data.error != 1) {

                    var list = []
                    data.data['roleList'].length > 0 && data.data['roleList'].map(data => {
                        list.push({
                            egrnID: data.EngRoleID,
                            egrsRole: data.egrsRole,
                            ID: data.ID,
                            actionId: 0,
                        })
                    })
                    console.log("List", list)

                    this.setState({
                        employeeId: data.data['employeeList'][0]['EmployeeID'],
                        prefixId: data.data['employeeList'][0]['PrefixID'] != null && data.data['employeeList'][0]['PrefixID'],
                        firstName: data.data['employeeList'][0]['FirstName'],
                        lastName: data.data['employeeList'][0]['LastName'],
                        code: data.data['employeeList'][0]['EmpCode'],
                        designationId: data.data['employeeList'][0]['DesignationID'],
                        departmentId: data.data['employeeList'][0]['DepartmentID'],
                        managerId: data.data['employeeList'][0]['ReportManagerID'],
                        partnerId: data.data['employeeList'][0]['PartnerID'],
                        dateofBirth: data.data['employeeList'][0]['DateofBirth'] != null
                            && data.data['employeeList'][0]['DateofBirth'] != '' ?
                            moment(data.data['employeeList'][0]['DateofBirth'], 'YYYY-MM-DD').toDate() : null,
                        email: data.data['employeeList'][0]['EmailID'],
                        genderId: data.data['employeeList'][0]['GenderID'],
                        bloodGroupId: data.data['employeeList'][0]['BloodGroupID'],
                        maritalStatusId: data.data['employeeList'][0]['MaritalStatus'],
                        anniversaryDate: data.data['employeeList'][0]['AniversaryDate'] != null && data.data['employeeList'][0]['AniversaryDate'] != '' ? moment(data.data['employeeList'][0]['AniversaryDate'], 'YYYY-MM-DD').toDate() : null,

                        joiningDate: data.data['employeeList'][0]['FromDate'] != null && data.data['employeeList'][0]['FromDate'] != '' ? moment(data.data['employeeList'][0]['FromDate'], 'YYYY-MM-DD').toDate() : null,
                        resignDate: data.data['employeeList'][0]['ToDate'] != null && data.data['employeeList'][0]['ToDate'] != '' ? moment(data.data['employeeList'][0]['ToDate'], 'YYYY-MM-DD').toDate() : null,
                        ratePerHour: data.data['employeeList'][0]['RatePerHour'],
                        contactNo: data.data['employeeList'][0]['ContactNo'],
                        accountNo: data.data['employeeList'][0]['AccountNo'],
                        membershipID: data.data['employeeList'][0]['MembershipID'],
                        isTimesheetMandatory: data.data['employeeList'][0]['MandatoryTimeSheet'],
                        isApprover: data.data['employeeList'][0]['IsApprover'],
                        leaveBal: data.data['employeeList'][0]['LeaveBal'],
                        creditLeaveBal: data.data['employeeList'][0]['CreditLeaveBal'],
                        leaveTaken: data.data['employeeList'][0]['LeaveTaken'],
                        creditLeaveTaken: data.data['employeeList'][0]['CreditLeaveTaken'],
                        isActive: data.data['employeeList'][0]['IsActive'],
                        totalAnualHours: data.data['employeeList'][0]['TotalAnualHours'],
                        userEngagementRoleId: data.data['employeeList'][0]['EngRoleName'],
                        addedRolePlayedList: list
                    })
                    this.setState({ disableFlag: true })
                    this.setState({ isLoading: false })
                    // console.log('addedRolePlayedList ', addedRolePlayedList)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // // Role Played
    onSelectEmployeeRolePlayed(e) {
        const { firstName, code, dateofBirth, joiningDate, designationId, rolePlayedList, addedRolePlayedList, employeeId } = this.state
        if (firstName != '' &&
            (dateofBirth != "" || dateofBirth != null) && (joiningDate != "" || joiningDate != null)
            && genderId != "" && designationId != "") {
            this.setState({ egrnID: e.target.value, rolePlayedId: e.target.value != "Select" ? e.target.value : "" })
            var isDataAvailable = false
            rolePlayedList.map((item) => {

                if (item.egrnID == e.target.value) {
                    addedRolePlayedList.map(data => {
                        if (data.egrnID == e.target.value) {
                            isDataAvailable = true
                        }
                        // else {
                        //     item.actionId = 1
                        // }
                    })
                    if (isDataAvailable) {
                        toast.error('Already added role', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                    else {
                        // item.EmployeeID= employeeId
                        item.actionId = 1
                        addedRolePlayedList.push(item)
                        this.setState({ addedRolePlayedList: addedRolePlayedList })

                        console.log("addedRolePlayedList", addedRolePlayedList)

                    }

                }
            })
        } else {
            toast.error('Please select mendatory fields', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true
            })
        }
    }

    // // Role Played Delete
    deleteEmployeeRoleplayed(e, roleId, egrnID) {
        e.preventDefault()
        const { addedRolePlayedList } = this.state

        var id = roleId
        console.log('roleId', roleId);
        this.setState({ isLoading: true })
        const filterList = addedRolePlayedList.filter(item => item.egrnID != egrnID);
        this.setState({ addedRolePlayedList: filterList })
        console.log("filterList", filterList)
        this.props
            .employeeRolePlayedDelete_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // employeeRolePlayed
    recordEngRole(employeeEngRoleList) {
        const { addedRolePlayedList } = this.state
        return (
            <table className="table table-bordered tb-action du-table w-50">
                <thead>
                    <tr>
                        <th></th>
                        <th style={{ textAlign: 'left' }} >Engagement Role</th>
                    </tr>
                </thead>
                <tbody>
                    {addedRolePlayedList.length > 0 ? (
                        addedRolePlayedList.map((data) => (
                            <tr key={data.egrnID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div>
                                        <a><span className="text-danger"><i className="fa fa-trash-o" onClick={(e) => this.deleteEmployeeRoleplayed(e, data.ID, data.egrnID)} /></span></a>

                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.egrsRole}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={5} className='text-center'>No records to display.</td></tr>)}
                </tbody>
            </table>
        )
    }

    handleAdd(e) {
        e.preventDefault()
        const { prefixId, firstName, lastName, code, designationId, departmentId, managerId, partnerId, dateofBirth, email, genderId, bloodGroupId, maritalStatusId, anniversaryDate, joiningDate, resignDate, ratePerHour, totalAnualHours, contactNo, accountNo, membershipID, isTimesheetMandatory, leaveBal, creditLeaveBal, isApprover, leaveTaken, creditLeaveTaken, rolePlayedId, addedRolePlayedList, userEngagementRoleId } = this.state

        if (firstName != '' && lastName != "" && email != "" && dateofBirth != "" &&
            dateofBirth != null && joiningDate != "" && joiningDate != null && genderId != "" && designationId != "" && userEngagementRoleId !='') {
            this.setState({ isLoading: true })
            var emailValidation = ''
            var contactValidation = ''
            emailValidation = email && emailValidator(email)
            contactValidation = contactNo && contactNoValidator(contactNo)
            if (emailValidation != '') {
                toast.error(emailValidation, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else if (contactValidation != '') {
                toast.error(contactValidation, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else {
                this.props
                    .addEmployeeList_action(prefixId, firstName.trim(), lastName.trim(), code, designationId, departmentId, managerId, partnerId, dateofBirth, email, genderId, bloodGroupId, maritalStatusId, anniversaryDate, joiningDate, resignDate, ratePerHour, contactNo, accountNo, membershipID, isTimesheetMandatory, leaveBal, creditLeaveBal, isApprover, leaveTaken, creditLeaveTaken, addedRolePlayedList, totalAnualHours, userEngagementRoleId)
                    .then((data) => {
                        if (data.error == 0) {
                            toast.success('Data added successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                            console.log("hello1234556474748654", data.data)
                            this.setState({ isLoading: false })
                            this.state.disableFlag = true
                            this.state.employeeId = data.data.employeeId

                            setTimeout(() => {
                                this.handleCancel()
                                this.setState({ activeTab: 'profile', disableFlag: true });
                            }, 700);
                        }
                        else {
                            this.setState({ isLoading: false })

                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }



    }

    // update employee
    handleUpdate(e) {
        e.preventDefault()
        const { prefixId, firstName, lastName, code, designationId, departmentId, managerId, totalAnualHours,
            partnerId, dateofBirth, email, genderId, bloodGroupId, maritalStatusId, anniversaryDate, joiningDate,
            resignDate, ratePerHour, contactNo, accountNo, membershipID, isTimesheetMandatory, leaveBal, creditLeaveBal, isApprover, leaveTaken, creditLeaveTaken, addedRolePlayedList, employeeId, userEngagementRoleId } = this.state

        const id = employeeId;

        if (firstName != '' && lastName != "" && email != "" && dateofBirth != "" && dateofBirth != null && joiningDate != "" &&
            joiningDate != null && genderId != "" && designationId != "" && userEngagementRoleId !='') {
            this.setState({ isLoading: true })
            var emailValidation = ''
            var contactValidation = ''
            emailValidation = email && emailValidator(email)
            contactValidation = contactNo && contactNoValidator(contactNo)
            if (emailValidation != '') {
                toast.error(emailValidation, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else if (contactValidation != '') {
                toast.error(contactValidation, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else {
                var list = addedRolePlayedList.filter(data => data.actionId == '1')
                console.log("updateEmployeeList_action 0909", list)
                this.props
                    .updateEmployeeList_action(id, prefixId, firstName.trim(), lastName.trim(), code, designationId, departmentId, managerId, partnerId, dateofBirth, email, genderId, bloodGroupId, maritalStatusId, anniversaryDate, joiningDate, resignDate, ratePerHour, contactNo, accountNo, membershipID, isTimesheetMandatory, leaveBal, creditLeaveBal, isApprover, leaveTaken, creditLeaveTaken, list, totalAnualHours, userEngagementRoleId)
                    .then((data) => {
                        console.log("updateEmployeeList_actiondata 0909 data", data.error)
                        console.log("updateEmployeeList_actiondata 0909 data", data['error'])

                        if (data.error != 1) {
                            toast.success('Record updated successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })

                            this.setState({ isLoading: false })
                            setTimeout(() => {
                                this.handleCancel()
                            }, 1000);
                        }
                        else {
                            this.setState({ isLoading: false })

                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    // delete financial year 
    handleDelete(e) {
        e.preventDefault()
        var id = this.state.EngRoleID
        this.setState({ isLoading: true })
        this.props
            .deleteEmployeeList_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.ErrMsg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // clear field  
    handleCancel() {
        this.props.navigate('/employeeList')
    }

    clearValue() {

        this.setState({
            prefixId: '',
            code: '',
            firstName: '',
            middleName: '',
            lastName: '',
            bloodGroup: '',
            gender: '',
            designationId: '',
            managerId: '',
            departmentId: '',
            partnerId: '',
            genderId: '',
            bloodGroupId: '',
            maritalStatusId: '',
            department: '',
            manager: '',
            department: '',
            partner: '',
            email: '',
            maritalStatus: "",
            anniversary: "",
            ratePerHour: "",
            isTimesheetMandatory: false,
            joiningDate: "",
            resignDate: "",
            leaveBal: "",
            balanceCreditLeave: "",
            leaveTaken: "",
            creditLeaveTaken: "",
            creditLeaveBal: '',
            contactNo: "",
            accountNo: "",
            membershipID: "",
            anniversaryDate: '',
            dateOfBirth: '',
            isApprover: false,
            rolePlayedId: '',
            Tel1: "",
            Tel2: "",
            contactPerson: "",
            contDateofBirth: '',
            contBloodGroupID: '',
            contEmail: '',
            addedRolePlayedList: [],
            creditLeaveTaken: "",
            dateofBirth: '',
            totalAnualHours: '',
            balanceCreditLeave: "",
        })
    }

    //****************************************/EmployeeContact/**********************************//

    EmployeeContactrecord(EmployeeContactList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }}  >Relation Type</th>
                        <th style={{ textAlign: 'left' }} >Contact Person</th>
                        <th style={{ textAlign: 'left' }} >Tel1</th>
                        <th style={{ textAlign: 'left' }} >Tel2</th>
                        <th style={{ textAlign: 'left' }} >Email</th>
                        <th style={{ textAlign: 'left' }} >Date Of Birth</th>
                        <th style={{ textAlign: 'left' }} >Blood Group ID</th>
                    </tr>
                </thead>
                <tbody>
                    {EmployeeContactList.length > 0 ? (
                        EmployeeContactList.map((data, i) => (
                            <tr key={data.ID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleTogleUpdate(data.ID)} /></a>
                                        <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModaldelete(data.ID)} /></span></a>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.ContactRelationID}</td>
                                <td style={{ textAlign: 'left' }}>{data.ContactPerson}</td>
                                <td style={{ textAlign: 'left' }}>{data.Tel1}</td>
                                <td style={{ textAlign: 'left' }}>{data.Tel2}</td>
                                <td style={{ textAlign: 'left' }}>{data.Email}</td>
                                <td style={{ textAlign: 'left' }}>{data.DateOfBirth != '' && data.DateOfBirth != null && moment(data.DateOfBirth).format('DD-MM-YYYY')}</td>
                                <td style={{ textAlign: 'left' }}>{data.BloodGroup}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={8} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    // get employee Contact List
    getEmployeeContactList() {
        const { employeeId, id } = this.state
        this.setState({ isLoading: true })
        this.props
            .employeeListContact_action(employeeId, id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ EmployeeContactList: data.data, isLoading: false, currentPage: 1 })

                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggle model for EmployeeContactAdd add
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    toggleModaldelete(employeeContactId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
        this.state.is_add = false
        this.state.is_update = false
        this.state.is_delete = true
        if (employeeContactId == '') {
            return;
        } else {
            this.setState({ employeeContactId: employeeContactId })
        }
    }

    handlecontactDelete(e) {
        e.preventDefault()
        var employeeId = this.state.employeeId
        var id = this.state.employeeContactId
        this.setState({ isLoading: true })
        this.props
            .deleteEmployeeContact_action(employeeId, id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getEmployeeContactList()
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // add employee contact 
    handleAddEmployeeContact(e) {
        e.preventDefault()
        const { employeeId, contactRelationID, contactPerson, Tel1, Tel2, contEmail, contDateofBirth, contBloodGroupID } = this.state

        if (contactPerson != '' && contactPerson != null) {
            this.setState({ isLoading: true })
            var emailValidation = ''
            var telephone1 = ''
            var telephone2 = ''
            emailValidation = contEmail && emailValidator(contEmail)
            telephone1 = Tel1 && contactNoValidator(Tel1)
            telephone2 = Tel2 && contactNoValidator(Tel2)
            if (emailValidation != '') {
                toast.error(emailValidation, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else if (telephone1 != '') {
                toast.error(telephone1, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else if (telephone2 != '') {
                toast.error(telephone2, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else {
                this.props
                    .addEmployeeContact_action(employeeId, contactRelationID, contactPerson, Tel1, Tel2, contEmail, contDateofBirth, contBloodGroupID)
                    .then((data) => {
                        if (data.error != 1) {
                            toast.success('Data added successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                            this.setState({ isLoading: false })
                            this.setState({ isOpen: false })
                            this.getEmployeeContactList()
                            // this.handleCancel()
                        }
                        else {
                            this.setState({ isLoading: false })

                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }

                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }



    }

    // update employee contact
    handleUpdateEmployeeContact(e) {
        e.preventDefault()
        const { contactId, employeeId, contactRelationID, contactPerson, Tel1, Tel2, contEmail, contDateofBirth, contBloodGroupID } = this.state
        const id = contactId;

        if (contactPerson != '' && contactPerson != null) {
            this.setState({ isLoading: true })
            var emailValidation = ''
            var telephone1 = ''
            var telephone2 = ''
            emailValidation = contEmail && emailValidator(contEmail)
            telephone1 = Tel1 && contactNoValidator(Tel1)
            telephone2 = Tel2 && contactNoValidator(Tel2)
            if (emailValidation != '') {
                toast.error(emailValidation, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else if (telephone1 != '') {
                toast.error(telephone1, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else if (telephone2 != '') {
                toast.error(telephone2, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                this.setState({ isLoading: false })
            }
            else {
                this.props
                    .updateEmployeeContact_action(id, employeeId, contactRelationID, contactPerson, Tel1, Tel2, contEmail, contDateofBirth, contBloodGroupID)
                    .then((data) => {
                        if (data.error != 1) {
                            toast.success('Data updated successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                            // isOpen={!isOpen}
                            this.setState({ isOpen: false })
                            this.setState({ isLoading: false })
                            this.getEmployeeContactList()
                        }
                        else {
                            this.setState({ isLoading: false })

                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    // Details employee contact
    getEmployeeContactDetails(contactId) {
        const { employeeId } = this.state
        console.log("09099", contactId, employeeId)
        // var id = ID
        this.setState({ isLoading: true })
        this.props
            .employeeContactDetail_action(contactId, employeeId)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        employeeId: data.data[0]['EmployeeID'],
                        contactId: data.data[0]['ID'],
                        contactRelationID: data.data[0]['BloodGroupID'],
                        contactPerson: data.data[0]['ContactPerson'],
                        Tel1: data.data[0]['Tel1'],
                        Tel2: data.data[0]['Tel2'],
                        contEmail: data.data[0]['Email'],
                        contDateofBirth: data.data[0]['DateOfBirth'] != null && data.data[0]['DateOfBirth'] != '' ? moment(data.data[0]['DateOfBirth'], 'YYYY-MM-DD').toDate() : null,
                        contBloodGroupID: data.data[0]['BloodGroupID'],

                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    handleTogleContactAdd() {
        // this.state.is_add = true
        // this.state.is_update = false
        // this.state.is_delete = false
        this.setState({ is_Contactadd: true })
        this.toggleModal()
        this.contClearValue()
    }

    handleTogleUpdate(contactId) {
        this.setState({ contactId: contactId, is_Contactadd: false })
        this.state.is_update = true
        this.state.is_add = false
        this.state.is_delete = false
        this.toggleModal()
        this.getEmployeeContactDetails(contactId)
    }

    otherDetails() {
        const { employeeId } = this.state
        if (employeeId == "") {
            toast.error('Please enter first employee field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    contClearValue() {

        this.setState({
            Tel1: "",
            Tel2: "",
            contactPerson: "",
            contDateofBirth: '',
            contBloodGroupID: '',
            contEmail: ''
        })
    }
    //****************************************//**********************************//

    render() {
        const { isLoading, isActive, is_update, is_add, isAccessToView, totalAnualHours, isAnyCompleted, designationId, is_Contactadd, designationList, managerList, managerId, departmentList, departmentId, partnerId, partnerList, genderId, genderList, bloodGroupId, bloodGroupList, maritalStatusId, maritalStatusList, isTimesheetMandatory,
            anniversaryDate, dateofBirth, resignDate, joiningDate, isApprover, prefixId, prefixList, leaveBal,
            leaveTaken, endDate, creditLeaveBal, rolePlayedId, rolePlayedList, employeeEngRoleList, isOpen, is_delete, firstName, lastName, EmployeeContactList, Tel1, Tel2, contactPerson, contDateofBirth, contBloodGroupID, contEmail, contactRelationID, disableFlag, activeTab, userEngagementRoleList, userEngagementRoleId } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />

                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {/* style={{ overflow : 'scroll !important', height : '200rem', backgroundColor : 'pink'}} */}
                        {isAccessToView ? (
                            <div className="main-panel main-box " >

                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a onClick={() => this.handleCancel()} >Employee</a>
                                            </li>
                                            <li>
                                                <a >{this.state.is_add ? 'New' : 'Update'}</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                        {
                                            is_update ? <p>
                                                {firstName != '' ? <strong className={isActive == 1 ? 'greena' : 'redi'}> {isActive == 1 ? 'Active' : 'Inactive'}</strong> : <></>}
                                            </p> : <></>
                                        }

                                    </div>
                                    <div className="row m-0">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html: "\n.accordion-button::after {\n background-size: 13px;\n display: none; \n} \n"
                                            }}
                                        />
                                        <div className="col-md-12 p-0 pg-body grid-margin stretch-card" >
                                            <div className="card">
                                                <div className="card-body pr-0">

                                                    <div className="mob-tab">
                                                        <div className="mob-wd1">
                                                            <ul
                                                                className="nav nav-tabs master-tab"
                                                                id="myTab"
                                                                role="tablist"
                                                            >
                                                                <li className="nav-item" role="presentation">
                                                                    <button
                                                                        className={`nav-link ${activeTab === 'home' ? 'active' : ''}`}

                                                                        // className="nav-link active"
                                                                        id="home-tab"
                                                                        data-bs-toggle="tab"
                                                                        data-bs-target="#home"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="home"
                                                                        aria-selected={activeTab === 'home'}
                                                                    >
                                                                        Employee
                                                                    </button>
                                                                </li>
                                                                {/* <li className="nav-item" role="presentation">
                                                                    <button
                                                                        className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                                                                      
                                                                        id="profile-tab"
                                                                        data-bs-toggle="tab"
                                                                        data-bs-target="#profile"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="profile"
                                                                        aria-selected={activeTab === 'profile'}
                                                                        disabled={!disableFlag}
                                                                    >
                                                                        <a onClick={() => this.otherDetails()}>Other Details</a>
                                                                    </button>
                                                                </li> */}

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-content h-auto"
                                                        id="myTabContent"
                                                        style={{ overflow: 'scroll' }}
                                                    >
                                                        <div
                                                            className={`tab-pane fade ${activeTab === 'home' ? 'active show' : ''}`}
                                                            // className="tab-pane fade active show"
                                                            id="home"
                                                            role="tabpanel"
                                                            aria-labelledby="home-tab"
                                                        >
                                                            {activeTab === 'home' ?
                                                                <div className='page-space-from-bottom p-1'>
                                                                    <div class="d-flex flex-wrap row ">
                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Prefix
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={prefixId}
                                                                                onChange={e => this.setState({ prefixId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {prefixList.length > 0 ? prefixList.map(item => (
                                                                                    <option
                                                                                        key={item.PrefixID}
                                                                                        value={item.PrefixID}>
                                                                                        {item.Prefix}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <div style={{ width: "100%" }}>
                                                                                <label>First Name<span class="required1">*</span></label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={firstName}
                                                                                    onChange={(e) => {
                                                                                        const inputValue = e.target.value;
                                                                                        // Regular expression to allow only text (letters) and spaces
                                                                                        const regex = /^[A-Za-z\s]+$/;
                                                                                        if (regex.test(inputValue) || inputValue === '') {
                                                                                            this.setState({ firstName: inputValue });
                                                                                        }
                                                                                    }}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Last Name<span class="required1">*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={lastName}
                                                                                onChange={(e) => {
                                                                                    const inputValue = e.target.value;
                                                                                    // Regular expression to allow only text (letters) and spaces
                                                                                    const regex = /^[A-Za-z\s]+$/;
                                                                                    if (regex.test(inputValue) || inputValue === '') {
                                                                                        this.setState({ lastName: inputValue });
                                                                                    }
                                                                                }}
                                                                                required
                                                                            />

                                                                        </div>
                                                                        <div class="form-group col-md-3 mt-1">
                                                                            <label>Code</label>
                                                                            <input

                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.code}
                                                                                onChange={(e) => this.setState({ code: e.target.value })}
                                                                                required
                                                                            />
                                                                        </div>

                                                                    </div>

                                                                    <div className="d-flex flex-wrap row mt-1">
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Designation<span className="required1">*</span>
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={designationId}
                                                                                onChange={e => this.setState({ designationId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {designationList.length > 0 ? designationList.map(item => (
                                                                                    <option
                                                                                        key={item.DesignationID}
                                                                                        value={item.DesignationID}>
                                                                                        {item.Designation}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Department
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={departmentId}
                                                                                onChange={e => this.setState({ departmentId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {departmentList.length > 0 ? departmentList.map(item => (
                                                                                    <option
                                                                                        key={item.DepartmentID}
                                                                                        value={item.DepartmentID}>
                                                                                        {item.Department}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>
                                                                        {/* <div className="form-group col-md-3 mt-1">
                                                                    <label>
                                                                        Manager
                                                                    </label>
                                                                    <select
                                                                        className="js-example-basic-single"
                                                                        style={{ width: "100%" }}

                                                                        value={managerId}
                                                                        onChange={e => this.setState({ managerId: e.target.value != "Select" ? e.target.value : "" })}

                                                                    >
                                                                         <option value={''}>Select</option>
                                                                        {managerList.length > 0 ? managerList.map(item => (
                                                                            <option
                                                                                key={item.EmployeeID}
                                                                                value={item.EmployeeID}>
                                                                                {item.Manager}
                                                                            </option>
                                                                        )) : <></>}
                                                                    </select>
                                                                </div> */}

                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Reporting Partner
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={partnerId}
                                                                                onChange={e => this.setState({ partnerId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {partnerList.length > 0 ? partnerList.map(item => (
                                                                                    <option
                                                                                        key={item.EmployeeID}
                                                                                        value={item.EmployeeID}>
                                                                                        {item.Partner}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Membership ID
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.membershipID}
                                                                                onChange={(e) => this.setState({ membershipID: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap row mt-1">

                                                                        <div className="form-group  col-3 ">
                                                                            <label>
                                                                                Date of Birth<span className="required1">*</span>
                                                                            </label>

                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={dateofBirth}
                                                                                    value={dateofBirth}

                                                                                    // onChange={(date) => 
                                                                                    //     console.log(date) }
                                                                                    onChange={(date) => {
                                                                                        this.setState({ dateofBirth: date });

                                                                                    }}
                                                                                    maxDate={this.maxDate}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Email<span className="required1">*</span>
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.email}
                                                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Gender<span className="required1">*</span>
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={genderId}
                                                                                onChange={e => this.setState({ genderId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {genderList.length > 0 ? genderList.map(item => (
                                                                                    <option
                                                                                        key={item.gennID}
                                                                                        value={item.gennID}>
                                                                                        {item.gensGender}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Blood Group
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={bloodGroupId}
                                                                                onChange={e => this.setState({ bloodGroupId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {bloodGroupList.length > 0 ? bloodGroupList.map(item => (
                                                                                    <option
                                                                                        key={item.BloodGroupID}
                                                                                        value={item.BloodGroupID}>
                                                                                        {item.BloodGroup}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex flex-wrap row mt-1">
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Marital Status
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={maritalStatusId}
                                                                                onChange={e => this.setState({ maritalStatusId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {maritalStatusList.length > 0 ? maritalStatusList.map(item => (
                                                                                    <option
                                                                                        key={item.mstnID}
                                                                                        value={item.mstnID}>
                                                                                        {item.mstsMaritalStatus}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Anniversary
                                                                            </label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={anniversaryDate}
                                                                                    value={anniversaryDate}
                                                                                    onChange={(date) => { this.setState({ anniversaryDate: date }) }}
                                                                                    minDate={dateofBirth ? new Date(dateofBirth.getFullYear() + 18, dateofBirth.getMonth(), dateofBirth.getDate()) : null}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Rate Per Hour
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                disabled={localStorage.getItem('RoleId') == '1'}
                                                                                value={this.state.ratePerHour}
                                                                                onChange={(e) => this.setState({ ratePerHour: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3 d-flex p-3">
                                                                            <input
                                                                                type="checkbox"
                                                                                selected={isTimesheetMandatory}
                                                                                checked={isTimesheetMandatory}
                                                                                value={isTimesheetMandatory}
                                                                                onChange={(e) => this.setState({ isTimesheetMandatory: !isTimesheetMandatory })}
                                                                                required
                                                                            />
                                                                            <label className="mt-2 mx-2" >
                                                                                Timesheet Mandatory
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Joining Date<span className="required1">*</span>
                                                                            </label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={joiningDate}

                                                                                    value={joiningDate}
                                                                                    onChange={(date) => { this.setState({ joiningDate: date }) }}
                                                                                    minDate={dateofBirth ? new Date(dateofBirth.getFullYear() + 15, dateofBirth.getMonth(), dateofBirth.getDate()) : null}
                                                                                    peekNextMonth
                                                                                    // maxDate={new Date()}
                                                                                    disabled={dateofBirth == ""}
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Resign Date
                                                                            </label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={resignDate}
                                                                                    value={resignDate}
                                                                                    minDate={joiningDate}
                                                                                    onChange={(date) => { this.setState({ resignDate: date }) }}

                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Balance Leave
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={leaveBal}
                                                                                onChange={(e) => this.setState({ leaveBal: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <label>
                                                                                Balance Credit Leave
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={creditLeaveBal}
                                                                                onChange={(e) => this.setState({ creditLeaveBal: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap row mt-1">
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Leave Taken
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={leaveTaken}
                                                                                onChange={(e) => this.setState({ leaveTaken: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Credit Leave Taken
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.creditLeaveTaken}
                                                                                onChange={(e) => this.setState({ creditLeaveTaken: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Contact No
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.contactNo}
                                                                                onChange={(e) => this.setState({ contactNo: e.target.value.replace(/[^0-9]/g, '').slice(0, 10) })}
                                                                                required
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Account No
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.accountNo}
                                                                                onChange={(e) => this.setState({ accountNo: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3 mt-1">
                                                                            <div >
                                                                                <label>Required Training Hours</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={totalAnualHours}
                                                                                    onChange={(e) => this.setState({ totalAnualHours: e.target.value.replace(/[^0-9]/g, '') })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                User Engagement Role<span className="required1">*</span>
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}
                                                                                value={userEngagementRoleId}
                                                                                onChange={e => this.setState({ userEngagementRoleId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {userEngagementRoleList.length > 0 ? userEngagementRoleList.map(item => (
                                                                                    <option
                                                                                        key={item.Code}
                                                                                        value={item.Code}>
                                                                                        {item.Name}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>

                                                                    </div>

                                                                    {/* <div className="d-flex flex-wrap row mt-1"> */}

                                                                    {/* <div className="form-group col-md-3 d-flex p-3">
                                                                    <input
                                                                        type="checkbox"
                                                                        selected={isApprover}
                                                                        checked={isApprover}
                                                                        value={isApprover}
                                                                        onChange={(e) => this.setState({ isApprover: !isApprover })}
                                                                        required
                                                                    />
                                                                    <label className="mt-2 mx-2">
                                                                        Is Approver
                                                                    </label>
                                                                </div> */}
                                                                    {/* </div> */}
                                                                    <hr className='m-2' />

                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            Role Played
                                                                        </label>
                                                                        <select
                                                                            className="js-example-basic-single"
                                                                            style={{ width: "100%" }}

                                                                            value={rolePlayedId}
                                                                            onChange={(e) => this.onSelectEmployeeRolePlayed(e)}
                                                                        // onChange={(e) => this.setState({ rolePlayedId: e.target.value != "Select" ? e.target.value : "" })}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {rolePlayedList.length > 0 ? rolePlayedList.map(item => (
                                                                                <option
                                                                                    key={item.egrnID}
                                                                                    value={item.egrnID}>
                                                                                    {item.egrsRole}
                                                                                </option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: '75px' }} >
                                                                        <div className="col-12 mt-2" >

                                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }} >
                                                                                {this.recordEngRole(employeeEngRoleList)}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {activeTab === 'home' ? <div className="footer-btn">
                                                                            <div className="col-md-3">
                                                                                <div
                                                                                    className="btn-group"
                                                                                    role="group"
                                                                                    aria-label="Basic example"
                                                                                >
                                                                                    {
                                                                                        this.state.is_add && <button
                                                                                            type="button"
                                                                                            className="btn btn-save footer-btn-inner mr-1"
                                                                                            onClick={(e) => this.handleAdd(e)}
                                                                                        >
                                                                                            Save
                                                                                        </button>
                                                                                    }

                                                                                    {
                                                                                        is_update &&
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-update footer-btn-inner mr-1"
                                                                                            onClick={(e) => this.handleUpdate(e)}
                                                                                        >
                                                                                            Update
                                                                                        </button>
                                                                                    }
                                                                                    {
                                                                                        is_add && <button
                                                                                            type="button"
                                                                                            className="btn btn-update footer-btn-inner mr-1"
                                                                                            onClick={() => this.clearValue()}
                                                                                        >
                                                                                            Reset
                                                                                        </button>
                                                                                    }
                                                                                    {
                                                                                        this.state.is_delete &&
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-delete footer-btn-inner mr-1"
                                                                                            onClick={() => this.handleDelete()}
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    }

                                                                                    <button style={{ marginRight: '5px' }}
                                                                                        type="button"
                                                                                        className="btn btn-cancel footer-btn-inner"
                                                                                        onClick={() => this.handleCancel()}
                                                                                    >
                                                                                        Cancel
                                                                                    </button>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            : <></>}
                                                                    </div>
                                                                </div>
                                                                : <></>
                                                            }


                                                        </div>
                                                        {/* Employee Contact Details */}
                                                        <div
                                                            className={`tab-pane fade  ${activeTab === 'profile' ? 'active show' : ''}`}

                                                            id="profile"
                                                            role="tabpanel"
                                                            aria-labelledby="profile-tab"
                                                        >

                                                            <div className='page-space-from-bottom  '>
                                                                <div className="form-group col-12">
                                                                    <div className="row">
                                                                        <div className="col-12 mt-2">
                                                                            <button type="button" className="btn btn-Add rounded-0 mb-2"
                                                                                style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={(e) => this.handleTogleContactAdd(e)} > Add</button>
                                                                            <h2 style={{ fontWeight: "bolder" }}>Contact</h2>
                                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                                {this.EmployeeContactrecord(EmployeeContactList)}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="footer-btn">
                                                                        <div className="col-md-3">
                                                                            <div
                                                                                className="btn-group"
                                                                                role="group"
                                                                                aria-label="Basic example"
                                                                            >
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-cancel footer-btn-inner "
                                                                                    onClick={() => this.handleCancel()}
                                                                                >
                                                                                    Cancel
                                                                                </button>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end*/}
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* model for detele */}

                {/* <Modal isOpen={isOpen && is_delete} onRequestClose={this.toggleModaldelete}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '400px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModaldelete} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handlecontactDelete(e)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModaldelete}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}



                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handlecontactDelete(e)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* // modal for add and update */}
                <Modal
                    isOpen={(isOpen && is_add) || (isOpen && is_update) || (isOpen && is_Contactadd)}
                    onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '550px' }}>
                            <div className="modal-header">
                                <h4 className="modal-title modal_title_text">Employee Contact</h4>
                                <button type="button " className="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: !isOpen })} >
                                    <span aria-hidden="true" className="pop-cancel">&times;</span></button>
                            </div>

                            <div className="d-flex flex-wrap row mt-1 p-2">
                                <div className="form-group col-md-6">
                                    <label>
                                        Relation Type<span class="required1">*</span>
                                    </label>
                                    <select
                                        className="js-example-basic-single"
                                        style={{ width: "100%" }}
                                        disabled={true}
                                    //     value={contactRelationID}
                                    // onChange={e => this.setState({ contactRelationID: e.target.value != "Select" ? e.target.value : "" })}

                                    >
                                        <option value={''}>Select</option>
                                        {/* {bloodGroupList.length > 0 ? bloodGroupList.map(item => (
                                            <option
                                                key={item.BloodGroupID}
                                                value={item.BloodGroupID}>
                                                {item.BloodGroup}
                                            </option>
                                        )) : <></>} */}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Contact Person
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contactPerson}
                                        onChange={(e) => this.setState({ contactPerson: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-wrap row mt-1 p-2">
                                <div className="form-group col-md-6">
                                    <label>
                                        Tel1
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={Tel1}
                                        onChange={(e) => this.setState({ Tel1: e.target.value.replace(/[^0-9]/g, '').slice(0, 10) })}
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Tel2
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={Tel2}
                                        onChange={(e) => this.setState({ Tel2: e.target.value.replace(/[^0-9]/g, '').slice(0, 10) })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-wrap row mt-1 p-2">
                                <div className="form-group col-md-6">
                                    <label>
                                        Date of Birth
                                    </label>
                                    <div className="form-group  d-flex">
                                        <DatePicker className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            selected={contDateofBirth}
                                            value={contDateofBirth}
                                            maxDate={this.maxDate}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onChange={(date) => { this.setState({ contDateofBirth: date }) }}

                                            customInput={<ExampleCustomInput />}
                                            dayClassName={() => "example-datepicker-day-class"}
                                            popperClassName="example-datepicker-class"
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contEmail}
                                        onChange={(e) => this.setState({ contEmail: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-wrap row mt-1 p-2">
                                <div className="form-group col-md-6">
                                    <label>
                                        Blood Group
                                    </label>
                                    <select
                                        className="js-example-basic-single"
                                        style={{ width: "100%" }}

                                        value={contBloodGroupID}
                                        onChange={e => this.setState({ contBloodGroupID: e.target.value != "Select" ? e.target.value : "" })}

                                    >
                                        <option value={''}>Select</option>
                                        {bloodGroupList.length > 0 ? bloodGroupList.map(item => (
                                            <option
                                                key={item.BloodGroupID}
                                                value={item.BloodGroupID}>
                                                {item.BloodGroup}
                                            </option>
                                        )) : <></>}
                                    </select>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="form-group text-left mod-fot">
                                        {/* <button
                                            type="button"
                                            className="btn btn-save footer-btn-inner mr-1"
                                            onClick={(e) => this.handleAddEmployeeContact(e)}
                                        >
                                            Save
                                        </button>
                                        <a href="javascript:void(0);" className="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isOpen: !isOpen })}> Cancel</a>
                                        <a href="javascript:void(0);" className="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.clearValue()}> Reset</a> */}
                                        {
                                            this.state.is_Contactadd && getModuleWriteAccess(Master) && <button
                                                type="button"
                                                className="btn btn-save footer-btn-inner m-2"
                                                onClick={(e) => this.handleAddEmployeeContact(e)}
                                            >
                                                Save
                                            </button>
                                        }

                                        {
                                            this.state.is_update && !is_Contactadd && getModuleUpdateAccess(Master) ?
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner m-2"
                                                    onClick={(e) => this.handleUpdateEmployeeContact(e)}
                                                >
                                                    Update
                                                </button> : <></>
                                        }
                                        {
                                            this.state.is_Contactadd && getModuleWriteAccess(Master) &&
                                            <button
                                                type="button"
                                                className="btn btn-update footer-btn-inner mr-1"
                                                onClick={() => this.contClearValue()}
                                            >
                                                Reset
                                            </button>
                                        }
                                        {
                                            <button
                                                type="button"
                                                className="btn btn-cancel footer-btn-inner "
                                                onClick={() => this.setState({ isOpen: !isOpen })}
                                            >
                                                Cancel
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        );
    }

}

export default withRouter(connect(mapStateToProps, {
    addEmployeeList_action, employeeDetail_action, updateEmployeeList_action, deleteEmployeeList_action
    , fill_action, employeeListContact_action, addEmployeeContact_action, updateEmployeeContact_action, employeeContactDetail_action, employeeRolePlayedDelete_action, deleteEmployeeContact_action
})(employeeAU));