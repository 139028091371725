import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
  addClientEngagement_action,
  updateClientEngagement_action,
  clientEngagementDetails_action,
  downloadPlane_action,
  designationDropdownList_action,
} from '../../../actions/engagement_action/client_engagement_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
  clientEngagementTeamMemEngPatQRActionId, ClientEngfinYearOnbasicClientActionId, ClientEngengagementTimeSheetActionId, teamDesignationDropdownActionId,
  engagementTypeId, qualityReviewerId, teamMemberOptionId, checklisttemplateActionId, clientEngagementActionId,
  clientsId, financialYearId, engagementPartnerId, engagementHeadActionId, clientEngagementNewChecklistActionId, locationActionId, clientEngagementChecklistActionId, clientEngagementNewChecklistForEditActionId, downloadReport,
  Client_Engagement
} from '../../../utils/constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { data } from 'jquery';
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
  // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state
})


const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
  <input
    value={value}
    className="example-custom-input"
    onClick={onClick}
    onChange={onChange}
    disabled={disabled}
    ref={ref}
  ></input>
));

class ClientEngagementAU extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tempClientNameList: [],
      searchClientsName: '',
      is_allSelected: false,
      is_allDeselected: false,
      details_clientId: '',


      status: 0,
      clientNameList: [],
      clientName: '',
      engagementTypeName: '',
      engagementTypeNameList: [],
      finYear: '',
      finYearList: [],
      location: '',
      locationList: [],
      auditPeriodStartDate: '',
      auditPeriodToDate: '',
      startDate: '',
      endDate: '',
      expectedClosureDate: '',
      remark: '',
      is_LocationBased: false,
      is_TimeBased: false,
      engPartnerName: '',
      qualityReviewerName: '',
      engHead: '',
      engHeadList: [],
      teamMemberId: '',
      teamMemberList: [],
      teamMemberAndDesignationList: [],
      tempTeamMemberAndDesignationList: [],
      checkListTemplateNameId: '',
      checkListData: [],
      is_critical: false,
      is_add: false,
      is_delete: false,
      is_update: false,
      isLoading: false,
      isOpen: false,
      newChecklistNameId: '',
      checkListTemplateNameList: [],
      newChecklistNameList: [],
      newChecklistNameEQ: '',
      isActive: 0,
      clientEngagementId: '',
      engagementAllocationId: '',
      engSelectedHeadList: [],
      engagementID: '',
      isAnyCompleted: false,
      comment: '',
      teamDesignationDropdownList: [],
      tempTeamDesignationDropdownList: [],
      teamDesignationDropdownId: "",
      checkDesignationListCondition: [],
    }
    this.handleSaveAndApprove = this.handleSaveAndApprove.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.getFillList = this.getFillList.bind(this)
    this.handleActionId = this.handleActionId.bind(this)
    this.handleDeselect = this.handleDeselect.bind(this)
    this.getClientEngagementDetails = this.getClientEngagementDetails.bind(this)
    this.onSelectFinYearDropdown = this.onSelectFinYearDropdown.bind(this)

    this.onClientNameSelected = this.onClientNameSelected.bind(this)
    this.onSave = this.onSave.bind(this)
    this.handleIscritical = this.handleIscritical.bind(this)
    this.onTeamMemberSelect = this.onTeamMemberSelect.bind(this)
    this.onRemoveTeamMember = this.onRemoveTeamMember.bind(this)
    this.onSelectNewChecklistName = this.onSelectNewChecklistName.bind(this)
    this.onSelectEngHead = this.onSelectEngHead.bind(this)
    this.onSaveAndMail = this.onSaveAndMail.bind(this)
    this.getDesignationList = this.getDesignationList.bind(this)
  }

  async componentDidMount() {

    this.setState({
      is_add: this.props.location.state.is_add,
      is_update: this.props.location.state.is_update,
      is_delete: this.props.location.state.is_delete,
      clientEngagementId: this.props.location.state.clientEngagementId
    })
    await this.getFillList(clientEngagementActionId, '', '', false)

    await this.getDesignationList()
    await this.getFillList(teamDesignationDropdownActionId, '', "", true)

    await this.getFillList(teamMemberOptionId, '', '', false)
    await this.getFillList(checklisttemplateActionId, '', '', false)
    await this.getFillList(engagementHeadActionId, '', '', false)
    setTimeout(() => {
      this.props.location.state.is_update && this.getClientEngagementDetails()
    }, 100);

  }


  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

  // get fill List dropdown  
  async getFillList(id, reference, reference2, isLoaderShow) {
    const { is_add, engPartnerName, engagementAllocationId, clientName, engHeadList, teamMemberList, is_update } = this.state
    if (isLoaderShow != false) {
      this.setState({ isLoading: true });
    }
    this.setState({ isLoading: true });
    await this.props
      .fill_action(id, reference, reference2)
      .then((data) => {
        if (data.error != 1) {
          if (id === clientEngagementActionId) {
            this.setState({
              clientNameList: data.data,
              // engagementAllocationId : data.data[0]['']
            })

          }
          else if (id === teamDesignationDropdownActionId) {
            this.setState({
              teamDesignationDropdownList: data.data,
              tempTeamDesignationDropdownList: data.data,
            })
          }
          else if (id === ClientEngengagementTimeSheetActionId) {
            this.setState({
              engagementTypeNameList: []
            })
            this.setState({
              engagementTypeNameList: data.data
            })
            if (is_add && data.data.length == 1) {
              this.setState({
                engagementTypeName: data.data[0]['EngagementTypeID'],
                engagementAllocationId: data.data[0]['EngagementAllocationID'],
                // isLoading: false
              })
              setTimeout(() => {
                this.getFillList(clientEngagementTeamMemEngPatQRActionId, data.data[0]['EngagementAllocationID'], "", false)

              }, 100);
            }
          }
          else if (id === teamMemberOptionId) {
            this.setState({
              teamMemberList: data.data
            })
          }
          else if (id === ClientEngfinYearOnbasicClientActionId) {
            console.log('Client1232 ****', clientName)
            console.log('ClientEngfinYearOnbasicClientActionId 1232', data.data, ClientEngfinYearOnbasicClientActionId,)
            this.setState({
              finYearList: [],
            })

            if (!is_update) {
              this.setState({
                engagementTypeNameList: []
              })
            }
            this.setState({
              finYearList: data.data,
            })
            is_add && this.setState({
              // finYear: data.data[0]['FinYearID'],
              auditPeriodStartDate: moment(data.data[0].FromDate, 'YYYY-MM-DD').toDate(),
              auditPeriodToDate: moment(data.data[0].ToDate, 'YYYY-MM-DD').toDate()
            })
            setTimeout(() => {
              if (is_add && data.data.length == 1) {
                this.setState({
                  finYear: data.data[0]['FinYearID'],
                  // isLoading: false
                })
                console.log('Client12323453 ****', clientName)
                this.getFillList(ClientEngengagementTimeSheetActionId, clientName, data.data[0]['FinYearID'], false)

              }
            }, 50);


          }
          else if (id === checklisttemplateActionId) {
            this.setState({
              checkListTemplateNameList: data.data
            })
          }

          else if (id === engagementHeadActionId) {
            this.setState({
              engHeadList: data.data
            })
          }
          else if (id === clientEngagementChecklistActionId) {
            this.setState({
              checkListData: data.data
            })
          }
          else if (id === locationActionId) {
            if (data.data.length == 1) {
              this.setState({
                location: data.data[0]['clcnID']
              })
            }
            this.setState({
              locationList: data.data
            })

          }
          else if (id === clientEngagementNewChecklistActionId || id === clientEngagementNewChecklistForEditActionId) {
            this.setState({
              newChecklistNameList: data.data
            })
          }
          else if (id === clientEngagementTeamMemEngPatQRActionId) {
            const filteredEngHeadList = engHeadList.filter(item =>
              item.EngagementHead !== data.data[0]['EngPartnerName']
              && item.EngagementHead !== data.data[0]['QualityReviewerName']
            );
            const filteredteamList = teamMemberList.filter(item =>
              item.TeamMember !== data.data[0]['EngPartnerName']
              && item.TeamMember !== data.data[0]['QualityReviewerName']
            );

            console.log("filteredEngHeadList", filteredEngHeadList)
            console.log("engHeadList", engHeadList)
            this.setState({
              engPartnerName: data.data[0]['EngPartnerName'],
              qualityReviewerName: data.data[0]['QualityReviewerName'],
              engHeadList: filteredEngHeadList,
              teamMemberList: filteredteamList

            })




          }

          this.setState({
            // isLoading: isLoaderShow == false ? is_add ? false : true : false

            isLoading: false
          })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // on remove  team member
  //  onTeamMemberSelect(id) {
  //   const { teamDesignationDropdownList, teamDesignationDropdownId,  is_update } = this.state
  //   // var list = teamMemberAndDesignationList.filter((item) => {
  //   //   if (item.TeamMemberID != id) {
  //   //     return item
  //   //   }
  //   //   else {
  //   //     if (is_update) {
  //   //       item.ActionID = 3
  //   //       tempTeamMemberAndDesignationList.push(item)
  //   //     }
  //   //   }
  //   // })

  //   // this.setState({ teamDesignationDropdownList: [] })
  // }



  // get client Engagement  details
  getClientEngagementDetails() {
    const { clientNameList, teamMemberList, checkListData, teamDesignationDropdownList, engagementAllocationID, clientName, engSelectedHeadList } = this.state
    var id = this.props.location.state.clientEngagementId
    // this.setState({ isLoading: true });
    // await this.getClientsList()
    this.setState({
      checkListData: []
    })
    this.props
      .clientEngagementDetails_action(id)
      .then((data) => {
        if (data.error != 1) {
          this.getFillList(locationActionId, data.data[0]['ClientID'], '', false)
          this.getFillList(ClientEngfinYearOnbasicClientActionId, data.data[0]['ClientID'], '', false)
          this.getFillList(ClientEngengagementTimeSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'], false)
          this.getFillList(clientEngagementTeamMemEngPatQRActionId, data.data[0]['EngagementAllocationID'], '', false)
          this.getFillList(clientEngagementNewChecklistForEditActionId, id, '', false)
          this.getFillList(engagementHeadActionId, '', '', false)


          this.setState({
            clientName: data.data[0]['ClientID'],
            finYear: data.data[0]['FinYearID'],
            engagementTypeName: data.data[0]['EngagementTypeID'],
            location: data.data[0]['LocationID'],
            startDate: moment(data.data[0]['StartDate'], 'YYYY-MM-DD').toDate(),
            endDate: moment(data.data[0]['EndDate'], 'YYYY-MM-DD').toDate(),
            remark: data.data[0]['Remark'],
            is_LocationBased: data.data[0]['IsLocationBased'],
            is_TimeBased: data.data[0]['IsTimeBased'],
            auditPeriodStartDate: moment(data.data[0]['AuditFromDate'], 'YYYY-MM-DD').toDate(),
            auditPeriodToDate: moment(data.data[0]['AuditToDate'], 'YYYY-MM-DD').toDate(),
            expectedClosureDate: moment(data.data[0]['ExpectedClosureDate'], 'YYYY-MM-DD').toDate(),
            engPartnerName: data.data[0]['EngPartnerName'],
            qualityReviewerName: data.data[0]['QualityReviewerName'],
            engagementAllocationId: data.data[0]['EngagementAllocationID'],
            engagementID: data.data[0]['EngagementID'],
            checkListTemplateNameId: data.data[0]['ChecklistTemplateID'],
            status: data.data[0]['Status'],
            isAnyCompleted: data.data[0]['EngagementStatusID'] != '2' ? false : true,
            // isAnyCompleted: data.data[0]['IsAnyCompleted'],
            comment: data.data[0]['Comment'],
          })


          console.log("teamList from db ", data.data[0]['teamList'])
          // team membar list
          var list = []
          var length = data.data[0]['teamList'].length;
          data.data[0]['teamList'].map((item, i) => {
            if (length - 1 === i) {
              var data = {
                'ActionID': 0,
                'EngagementRoleID': item.EngagementRoleID,
                'TeamMemberID': item.TeamMemberID,
                'TeamMember': item.TeamMember,
                'Designation': item.Designation,
                'EngMemberID': item.EngMemberID,
                'EngRoleName': item.EngagementRoleName,
              }
              engSelectedHeadList.push(data)

              console.log("engHead from details", item.TeamMemberID)
              console.log("engHead from details engSelectedHeadList", engSelectedHeadList)

              this.setState({
                engSelectedHeadList: engSelectedHeadList
              })
              this.setState({
                // engHead:  item.TeamMemberID,
                engHead: engSelectedHeadList[0]['TeamMemberID'],

              })
            }
            else if (length - 1 != i) {
              var code = '';
              var desc = '';
              teamMemberList.map((data) => {
                if (data.TeamMemberID == item.TeamMemberID) {
                  code = data.desnID;
                  desc = data.EngRoleName;
                }
              });

              var data = {
                'ActionID': 0,
                'EngagementRoleID': item.EngagementRoleID,
                'TeamMemberID': item.TeamMemberID,
                'TeamMember': item.TeamMember,
                'Designation': item.Designation,
                'EngMemberID': item.EngMemberID,
                'EngRoleName': item.EngagementRoleName,
                'arr': [...teamDesignationDropdownList, {
                  "Code": code,
                  "Description": desc,
                }]
              }
              list.push(data)
              // list.push(item)
            }

          })
          console.log("prepare teamMemberAndDesignationList", list)
          data.data[0]['checkList'].map((item) => {
            var data = {
              'ActionID': 0,
              'EngagementChecklistID': item.EngagementChecklistID,
              'ChecklistID': item.ChecklistID,
              'ChecklistName': item.ChecklistName,
              'EQID': item.EQID,
              'IsCritical': item.IsCritical,
              'Name': item.Name,
              'Selected': item.Selected,
            }
            checkListData.push(data)

            // engSelectedHeadList
            this.setState({
              checkListData: checkListData
            })
            this.forceUpdate();

          })
          this.setState({ teamMemberAndDesignationList: list, })


          this.setState({
            engagementID: data.data[0]['EngagementID'],
            engHead: engSelectedHeadList[0]['TeamMemberID'],
            finYear: data.data[0]['FinYearID'],
          })


          // setTimeout(() => {
          //   this.setState({ isLoading: false })
          // }, 1000);

        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })

  }

  //on select team member
  onTeamMemberSelect(e) {
    const { teamMemberId, teamMemberList, tempTeamDesignationDropdownList, teamDesignationDropdownList, teamMemberAndDesignationList, is_update } = this.state
    this.setState({ teamMemberId: e.target.value })
    var isDataAvailable = false
    teamMemberList.length > 0 ? teamMemberList.map((item) => {
      if (item.TeamMemberID == e.target.value) {
        if (teamMemberAndDesignationList.length == 0) {
          item.ActionID = 1
          item.EngagementRoleID = item.desnID
          var code = '';
          var desc = '';
          teamMemberList.map((data) => {
            if (data.TeamMemberID == item.TeamMemberID) {
              code = data.desnID;
              desc = data.EngRoleName;
            }
          });
          //  var list
          item.arr = [...teamDesignationDropdownList, {
            "Code": code,
            "Description": desc,
          }]
          console.log("item.arr *****", item.arr)
          teamMemberAndDesignationList.push(item)
          console.log("teamMemberAndDesignationList *****", teamMemberAndDesignationList)
          this.setState({ teamMemberAndDesignationList: [...teamMemberAndDesignationList] })
        }
        else {
          teamMemberAndDesignationList.map(data => {
            if (data.TeamMemberID == e.target.value) {
              isDataAvailable = true
            }
          })
          if (!isDataAvailable) {
            item.ActionID = 1
            item.EngagementRoleID = item.desnID
            var code = '';
            var desc = '';
            teamMemberList.map((data) => {
              if (data.TeamMemberID == item.TeamMemberID) {
                code = data.desnID;
                desc = data.EngRoleName;
              }
            });
            //  var list
            item.arr = [...teamDesignationDropdownList, {
              "Code": code,
              "Description": desc,
            }]
            teamMemberAndDesignationList.push(item)
            this.setState({ teamMemberAndDesignationList: [...teamMemberAndDesignationList] })
          }
          else {
            toast.error('Team member already Selected.', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        }
        this.setState({ teamMemberId: '' })
      }
    }) : <></>
  }

  onSelectTeamDesignationDropdownList(value, item, EngagementRoleID) {
    const { teamMemberAndDesignationList, teamMemberList, is_update, tempTeamDesignationDropdownList, teamDesignationDropdownList } = this.state

    const updatedList = [...teamMemberAndDesignationList].map(member => {
      if (member.TeamMemberID === item.TeamMemberID) {

        var code = '';
        var desc = '';
        teamMemberList.map((data) => {
          if (data.TeamMemberID == item.TeamMemberID) {
            code = data.desnID;
            desc = data.EngRoleName;
          }
        });
        return {
          ...member,
          ActionID: 2,
          EngagementRoleID: value.includes("Independent Peer Reviewer") ||
            value.includes("Engagement Manager") ?
            "0" : code,
          EngRoleName: value,
          arr: [...teamDesignationDropdownList, {
            "Code": code,
            "Description": desc,
          }]


        };
      }
      return member;
    });
    this.setState({
      teamMemberAndDesignationList: updatedList,
    });

  }

  onSelectTeamDesignationDropdownList1(value, item, EngagementRoleID) {
    const { teamMemberAndDesignationList, is_update, tempTeamDesignationDropdownList, teamDesignationDropdownList } = this.state
    this.setState({
      teamDesignationDropdownList: [...tempTeamDesignationDropdownList]
    });

    var disc = ''
    var code = ''
    var dataList = [...tempTeamDesignationDropdownList]
    const updatedList = [...teamMemberAndDesignationList].map(member => {
      if (member.TeamMemberID === item.TeamMemberID) {
        dataList.push({
          "Code": member.desnID,
          "Description": member.EngRoleName,
        })


        const removeDuplicates = (array) => {
          const uniqueMap = new Map();
          array.forEach(item => {
            uniqueMap.set(item.Description, item);
          });
          return Array.from(uniqueMap.values());
        };

        const uniqueDataList = removeDuplicates(dataList);
        return {
          ...member,
          ActionID: 2,
          EngagementRoleID: '0',
          EngRoleName: value,
          // arr: [...tempTeamDesignationDropdownList],
          arr: uniqueDataList,

        };
      }
      return member;
    });

    this.setState({
      teamMemberAndDesignationList: updatedList,
    });
  }


  addOnTeamDesignationDropdownList(teamId) {
    const { teamMemberAndDesignationList, tempTeamDesignationDropdownList, teamDesignationDropdownList, teamMemberList, is_update } = this.state
    var list = []
    var preselectedCode = ""
    list = tempTeamDesignationDropdownList
    teamMemberList.map(member => {
      if (teamId === member.TeamMemberID) {
        const existingMember = teamDesignationDropdownList.find(item => item.Code == member.EngagementRoleID);
        if (!existingMember) {
          preselectedCode = member.EngRoleName
          const isEngRoleNamePresent = list.find(item => item.Description == member.EngRoleName);
          // If the EngRoleName is not present in the list, push a new object into the list
          if (!isEngRoleNamePresent) {
            list.push({ Code: member.EngagementRoleID, Description: member.EngRoleName });
          }

        }
      }
    });
    const filteredList = list.filter(item => item.Code == "1" || item.Code == "2" || item.Description == preselectedCode);

    this.setState({
      teamDesignationDropdownList: filteredList
    });
  }


  // on remove  team member
  onRemoveTeamMember(id) {
    const { tempTeamMemberAndDesignationList, teamMemberId, teamMemberList, teamMemberAndDesignationList, is_update } = this.state
    var list = teamMemberAndDesignationList.filter((item) => {
      if (item.TeamMemberID != id) {
        return item
      }
      else {
        if (is_update) {
          item.ActionID = 3
          console.log("on delete item", item)
          tempTeamMemberAndDesignationList.push(item)
        }
      }
    })

    console.log("on delete list", list)

    this.setState({ teamMemberAndDesignationList: list })
  }



  // on select new check list 

  onSelectNewChecklistName(e, id) {
    const { newChecklistNameEQ, newChecklistNameId, newChecklistNameList, checkListData, is_update } = this.state
    this.setState({ newChecklistNameId: e.target.value })
    var isAvailable = false
    newChecklistNameList.map((item, index) => {
      if (item.ChecklistID == e.target.value) {
        checkListData.map((data, index) => {
          if (data.ChecklistID === item.ChecklistID) {
            // already contained
            isAvailable = true
          }
        })

        if (isAvailable) {
          this.setState({ newChecklistNameEQ: '', newChecklistNameId: '' })
          toast.error('Checklist already added.', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
        else {
          // add new check list
          this.setState({ newChecklistNameEQ: item.Name })
          checkListData.push(item)

          if (is_update) {
            checkListData.map((data, index) => {
              if (data.ChecklistID === item.ChecklistID) {
                data.EngagementChecklistID = ''
                data.Selected = 1
                data.ActionID = 1
              }
            })
          }
          else {
            /// add
            checkListData.map((data, index) => {
              if (data.ChecklistID === item.ChecklistID) {
                data.EngagementChecklistID = ''
                data.Selected = 1
              }
            })
          }
          console.log('checkListData 90909', checkListData)
          this.setState({ checkListData: checkListData, newChecklistNameId: '', newChecklistNameEQ: '' })

        }
      }
    })


  }
  // add  new template
  handleSaveAndApprove(e) {
    e.preventDefault()
    const { is_add } = this.state
    is_add ? this.onSave() : this.handleUpdate(e)
  }

  onSave() {
    const { clientName, engagementTypeName, finYear, location,
      auditPeriodStartDate, auditPeriodToDate, startDate, endDate,
      expectedClosureDate, remark, is_LocationBased, is_TimeBased, engPartnerName, locationList,
      qualityReviewerName, engHead, teamMemberAndDesignationList, checkListTemplateNameId,
      checkListData, newChecklistNameId, newChecklistNameEQ, isActive, engHeadList, engagementAllocationId, comment
    } = this.state

    // team member list
    var teamList = []

    for (let i = 0; i < teamMemberAndDesignationList.length; i++) {
      var data = {
        'ActionID': 1,
        'EngMemberID': '',
        'EngagementRoleID': teamMemberAndDesignationList[i]['EngagementRoleID'],
        'TeamMemberID': teamMemberAndDesignationList[i]['TeamMemberID'],
        'EngagementRoleName': teamMemberAndDesignationList[i]['EngRoleName'],
      }
      teamList.push(data)
    }
    for (let i = 0; i < engHeadList.length; i++) {
      // console.log(location != '')
      if (engHead == engHeadList[i]['emmnID']) {
        console.log(engHeadList[i])

        var data = {
          'ActionID': 1,
          'EngMemberID': '',
          'EngagementRoleID': engHeadList[i]['desnID'],
          'TeamMemberID': engHeadList[i]['emmnID'],
          'EngagementRoleName': ""

        }
        teamList.push(data)
      }
    }

    // check list 
    var checklist = []
    for (let i = 0; i < checkListData.length; i++) {
      if (checkListData[i]['Selected'] === 1) {
        var data = {
          'ActionID': 1,
          'EngagementChecklistID': '',
          'ChecklistID': checkListData[i]['ChecklistID'],
          'EQID': checkListData[i]['EQID'],
          'IsCritical': checkListData[i]['IsCritical'],
          'IsCompleted': false,
          'CompletedBy': '',
          'CompletedDate': null,
          'CompletedByRemark': '',
          'PreparedBy': '',
          'IsReviewed': '',
          'ReviewedBy': '',
          'ReviewedDate': null,
          'ReviewedByRemark': '',
          'Isok': false,
          'File': '',

        }
        checklist.push(data)
      }
    }
    // checklist = checkListData.filter((item,index) => { return item.Selected === 1})

    console.log(teamList)
    if (clientName != "" && engagementTypeName != "" && finYear != "" &&
      (startDate != "" && startDate != null && startDate != "Invalid Date")
      && (endDate != "" && endDate != null && endDate != "Invalid Date")
      && (expectedClosureDate != "" && expectedClosureDate != null && expectedClosureDate != "Invalid Date")
      && checkListTemplateNameId != "" && engHead != "") {
      console.log('location........', locationList)
      console.log('location........', locationList.length > 0)
      var isFollow = false
      if (locationList.length != 0) {
        console.log('test save')
        console.log(locationList.length != 1)
        console.log(location != '')
        if (is_LocationBased && location != '') {
          isFollow = true

        }
        else {
          this.setState({ isLoading: false })
          if (is_LocationBased) {
            toast.error('Please select location', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            isFollow = false
          } else {
            isFollow = true

          }
        }
      } else {
        isFollow = true
      }
    } else {
      this.setState({ isLoading: false })
      toast.error('Please enter mandatory field', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })

    }


    if (isFollow) {
      if (checklist.length == 0) {
        this.setState({ isLoading: false })
        toast.error('Atleast one checklist must be selected.', {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: true,
        })
      } else {
        this.toggleModal()
      }
    }

  }
  // update template
  handleUpdate(e) {
    e.preventDefault()
    const { clientName, engagementTypeName, finYear, location, engSelectedHeadList,
      auditPeriodStartDate, auditPeriodToDate, startDate, endDate,
      expectedClosureDate, remark, is_LocationBased, is_TimeBased, engPartnerName,
      qualityReviewerName, engHead, tempTeamMemberAndDesignationList, teamMemberAndDesignationList, checkListTemplateNameId,
      checkListData, newChecklistNameId, engagementID, isActive, engHeadList, engagementAllocationId, comment
    } = this.state
    console.log('update .....')
    var id = this.props.location.state.clientEngagementId
    // team member list
    var teamList = []
    for (let i = 0; i < teamMemberAndDesignationList.length; i++) {
      if (teamMemberAndDesignationList[i]['ActionID'] != 0) {
        var data = {
          'ActionID': teamMemberAndDesignationList[i]['ActionID'],
          'EngMemberID': teamMemberAndDesignationList[i]['EngMemberID'],
          'EngagementRoleID': teamMemberAndDesignationList[i]['EngagementRoleID'],
          'EngagementRoleName': teamMemberAndDesignationList[i]['EngRoleName'],
          'TeamMemberID': teamMemberAndDesignationList[i]['TeamMemberID'],
        }
        teamList.push(data)
      }
    }
    // deleted team member
    for (let i = 0; i < tempTeamMemberAndDesignationList.length; i++) {
      var data = {
        'ActionID': tempTeamMemberAndDesignationList[i]['ActionID'],
        'EngMemberID': tempTeamMemberAndDesignationList[i]['EngMemberID'],
        'EngagementRoleID': tempTeamMemberAndDesignationList[i]['EngagementRoleID'],
        'EngagementRoleName': teamMemberAndDesignationList[i]['EngRoleName'],
        'TeamMemberID': tempTeamMemberAndDesignationList[i]['TeamMemberID'],
      }
      teamList.push(data)
    }

    for (let i = 0; i < engSelectedHeadList.length; i++) {
      if (engSelectedHeadList[i]['ActionID'] != 0) {
        var data = {
          'ActionID': engSelectedHeadList[i]['ActionID'],
          'EngMemberID': engSelectedHeadList[i]['EngMemberID'],
          'EngagementRoleID': engSelectedHeadList[i]['EngagementRoleID'],
          'EngagementRoleName': engSelectedHeadList[i]['EngRoleName'],
          'TeamMemberID': engSelectedHeadList[i]['TeamMemberID'],
        }
        teamList.push(data)
      }
    }

    // check list 
    var checklist = []
    for (let i = 0; i < checkListData.length; i++) {
      if (checkListData[i]['Selected'] && checkListData[i]['ActionID'] != 0 || checkListData[i]['ActionID'] == 3) {
        var data = {
          'ActionID': checkListData[i]['ActionID'],
          'EngagementChecklistID': checkListData[i]['EngagementChecklistID'],
          'ChecklistID': checkListData[i]['ChecklistID'],
          'EQID': checkListData[i]['EQID'],
          'IsCritical': checkListData[i]['IsCritical'],
          'IsCompleted': false,
          'CompletedBy': '',
          'CompletedDate': null,
          'CompletedByRemark': '',
          'PreparedBy': '',
          'IsReviewed': '',
          'ReviewedBy': '',
          'ReviewedDate': null,
          'ReviewedByRemark': '',
          'Isok': checkListData[i]['Isok'] != null ? checkListData[i]['Isok'] : false,
          'File': '',

        }
        checklist.push(data)
      }
    }

    console.log("check.......", startDate)
    console.log("check.......", endDate)
    if (clientName != "" && engagementTypeName != "" && finYear != "" &&
      (startDate != "" && startDate != null && startDate != "Invalid Date")
      && (endDate != "" && endDate != null && endDate != "Invalid Date")
      && (expectedClosureDate != "" && expectedClosureDate != null && expectedClosureDate != "Invalid Date")
      && checkListTemplateNameId != "" && engHead != "") {
      var isAnySelected = false;
      for (var i = 0; i < checkListData.length; i++) {
        if (checkListData[i]['Selected']) {
          isAnySelected = true;
        }
      }
      if (!isAnySelected) {
        this.setState({ isLoading: false })
        toast.error('Atleast one checklist must be selected.', {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: true,
        })
      } else {
        // this.toggleModal()

        this.onSaveAndMail(e, false)
      }
    } else {
      toast.error('Please enter mandatory field', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }

  }
  //final save and update................
  onSaveAndMail(e, email) {
    e.preventDefault()
    const { is_add } = this.state
    //for adding........................
    if (is_add) {
      const { clientName, engagementTypeName, finYear, location,
        auditPeriodStartDate, auditPeriodToDate, startDate, endDate,
        expectedClosureDate, remark, is_LocationBased, is_TimeBased, engPartnerName, locationList,
        qualityReviewerName, engHead, teamMemberAndDesignationList, checkListTemplateNameId,
        checkListData, newChecklistNameId, newChecklistNameEQ, isActive, engHeadList, engagementAllocationId, comment
      } = this.state

      // team member list
      var teamList = []
      for (let i = 0; i < teamMemberAndDesignationList.length; i++) {
        var data = {
          'ActionID': 1,
          'EngMemberID': '',
          'EngagementRoleID': teamMemberAndDesignationList[i]['EngagementRoleID'],
          'TeamMemberID': teamMemberAndDesignationList[i]['TeamMemberID'],
          'EngagementRoleName': teamMemberAndDesignationList[i]['EngRoleName'],
          'Typ': 'A'
        }
        teamList.push(data)
      }
      for (let i = 0; i < engHeadList.length; i++) {
        if (engHead == engHeadList[i]['emmnID']) {

          var data = {
            'ActionID': 1,
            'EngMemberID': '',
            'EngagementRoleID': engHeadList[i]['desnID'],
            'TeamMemberID': engHeadList[i]['emmnID'],
            'EngagementRoleName': "",
            'Typ': 'H'
          }
          teamList.push(data)
        }
      }

      // check list 
      var checklist = []
      for (let i = 0; i < checkListData.length; i++) {
        if (checkListData[i]['Selected'] === 1) {
          var data = {
            'ActionID': 1,
            'EngagementChecklistID': '',
            'ChecklistID': checkListData[i]['ChecklistID'],
            'EQID': checkListData[i]['EQID'],
            'IsCritical': checkListData[i]['IsCritical'],
            'IsCompleted': false,
            'CompletedBy': '',
            'CompletedDate': null,
            'CompletedByRemark': '',
            'PreparedBy': '',
            'IsReviewed': '',
            'ReviewedBy': '',
            'ReviewedDate': null,
            'ReviewedByRemark': '',
            'Isok': false,
            'File': '',

          }
          checklist.push(data)
        }
      }
      console.log("**", location)
      console.log("** 1")
      //
      var isFollow = false;
      if (locationList.length != 0) {
        console.log('test save')
        console.log(locationList.length != 1)
        console.log(location != '')
        if (is_LocationBased && location != '') {
          isFollow = true

        }
        else {
          this.setState({ isLoading: false })
          if (is_LocationBased) {
            toast.error('Please select location', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            isFollow = false
          } else {
            isFollow = true

          }
        }
      } else {
        isFollow = true
      }
      //
      if (isFollow) {
        this.setState({ isLoading: true, isOpen: false })
        this.props
          .addClientEngagement_action(clientName, engagementTypeName, finYear,
            location, is_LocationBased, is_TimeBased, auditPeriodStartDate, auditPeriodToDate,
            startDate, endDate, expectedClosureDate, remark, teamList, checklist,
            checkListTemplateNameId, engagementAllocationId, comment, email
          )
          .then((data) => {
            if (data.error != 1) {
              toast.success('Data added successfully', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
              this.setState({ isLoading: false })
              setTimeout(() => {
                this.handleCancel()
              }, 300);

            }
            else {
              this.setState({ isLoading: false })
              toast.error(data.msg, {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }

    } else {
      //for updating....................
      const { clientName, engagementTypeName, finYear, location, engSelectedHeadList, locationList,
        auditPeriodStartDate, auditPeriodToDate, startDate, endDate,
        expectedClosureDate, remark, is_LocationBased, is_TimeBased, engPartnerName,
        qualityReviewerName, engHead, tempTeamMemberAndDesignationList, teamMemberAndDesignationList, checkListTemplateNameId,
        checkListData, newChecklistNameId, engagementID, isActive, engHeadList, engagementAllocationId, comment
      } = this.state
      var id = this.props.location.state.clientEngagementId
      // team member list
      var teamList = []
      console.log('teamMemberAndDesignationList', teamMemberAndDesignationList)

      for (let i = 0; i < teamMemberAndDesignationList.length; i++) {
        if (teamMemberAndDesignationList[i]['ActionID'] != 0) {
          var data = {
            'ActionID': teamMemberAndDesignationList[i]['ActionID'],
            'EngMemberID': teamMemberAndDesignationList[i]['EngMemberID'],
            'EngagementRoleID': teamMemberAndDesignationList[i]['EngagementRoleID'],
            'TeamMemberID': teamMemberAndDesignationList[i]['TeamMemberID'],
            'EngagementRoleName': teamMemberAndDesignationList[i]['EngRoleName'],
            'Typ': 'A'
          }
          teamList.push(data)
        }
      }
      console.log('teamList', teamList)

      // deleted team member
      for (let i = 0; i < tempTeamMemberAndDesignationList.length; i++) {
        var data = {
          'ActionID': tempTeamMemberAndDesignationList[i]['ActionID'],
          'EngMemberID': tempTeamMemberAndDesignationList[i]['EngMemberID'],
          'EngagementRoleID': tempTeamMemberAndDesignationList[i]['EngagementRoleID'],
          'TeamMemberID': tempTeamMemberAndDesignationList[i]['TeamMemberID'],
          'EngagementRoleName': teamMemberAndDesignationList[i]['EngRoleName'],
          'Typ': 'A'
        }
        teamList.push(data)
      }
      console.log('teamList', teamList)

      for (let i = 0; i < engSelectedHeadList.length; i++) {
        if (engSelectedHeadList[i]['ActionID'] != 0) {
          var data = {
            'ActionID': engSelectedHeadList[i]['ActionID'],
            'EngMemberID': engSelectedHeadList[i]['EngMemberID'],
            'EngagementRoleID': engSelectedHeadList[i]['EngagementRoleID'],
            'TeamMemberID': engSelectedHeadList[i]['TeamMemberID'],
            'EngagementRoleName': engSelectedHeadList[i]['EngagementRoleName'],
            'Typ': 'H'
          }
          teamList.push(data)
        }
      }
      console.log('teamList', teamList)

      // check list 
      var checklist = []
      for (let i = 0; i < checkListData.length; i++) {
        if (checkListData[i]['Selected'] && checkListData[i]['ActionID'] != 0 || checkListData[i]['ActionID'] == 3) {
          var data = {
            'ActionID': checkListData[i]['ActionID'],
            'EngagementChecklistID': checkListData[i]['EngagementChecklistID'],
            'ChecklistID': checkListData[i]['ChecklistID'],
            'EQID': checkListData[i]['EQID'],
            'IsCritical': checkListData[i]['IsCritical'],
            'IsCompleted': false,
            'CompletedBy': '',
            'CompletedDate': null,
            'CompletedByRemark': '',
            'PreparedBy': '',
            'IsReviewed': '',
            'ReviewedBy': '',
            'ReviewedDate': null,
            'ReviewedByRemark': '',
            'Isok': checkListData[i]['Isok'] != null ? checkListData[i]['Isok'] : false,
            'File': '',

          }
          checklist.push(data)
        }
      }
      var isFollow = false
      if (locationList.length != 0) {
        console.log('test save')
        console.log(locationList.length != 1)
        console.log(location != '')
        if (is_LocationBased && location != '') {
          isFollow = true

        }
        else {
          this.setState({ isLoading: false })
          if (is_LocationBased) {
            toast.error('Please select location', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            isFollow = false
          } else {
            isFollow = true

          }
        }
      } else {
        isFollow = true
      }


      if (isFollow) {
        this.setState({ isLoading: true, isOpen: false })
        this.props
          .updateClientEngagement_action(id, clientName, engagementTypeName, finYear,
            location != null && location != NaN ? location : '', is_LocationBased, is_TimeBased, auditPeriodStartDate, auditPeriodToDate,
            startDate, endDate, expectedClosureDate, remark, teamList, checklist,
            checkListTemplateNameId, engagementAllocationId, comment, email
          )
          .then((data) => {
            if (data.error != 1) {
              toast.success('Data updated successfully', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
              this.setState({ isLoading: false })
              setTimeout(() => {
                this.handleCancel()
              }, 300);
            }
            else {
              this.setState({ isLoading: false })
              toast.error(data.msg, {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }

    }
  }

  // delete template
  handleDelete(e) {
    e.preventDefault()
    var id = 1;
    this.setState({ isLoading: true })
    this.props
      .deleteEngagementAllocation_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isLoading: false })
        }
        else {
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // get designation list
  async getDesignationList(e) {
    // e.preventDefault()
    var id = 1;
    this.setState({ isLoading: true })
    this.props
      .designationDropdownList_action(id)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ isLoading: false, checkDesignationListCondition: data.data })
        }
        else {
          this.setState({ isLoading: false })
          // toast.error(data.msg, {
          //   theme: 'colored',
          //   autoClose: 3000,
          //   hideProgressBar: true,
          // })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // clear field ,list
  handleCancel() {
    // this.props.navigate('/clientEngagement')
    this.props.navigate(-1)
  }

  // download audit plane...............
  handleDownload(e) {
    e.preventDefault()
    const { clientEngagementId } = this.state
    this.setState({ isLoading: true })
    this.props
      .downloadPlane_action(clientEngagementId)
      .then((data) => {
        if (data != null) {
          this.setState({ isLoading: false })
          console.log('Report data : ', data);
          var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
          console.log('Report download url : ', url);
          const win = window.location.replace(url, '_blank');
          if (win != null) {
            win.focus();
          }
        } else {
          this.setState({ isLoading: false })
          toast.error('There is some problem to download report. Please try again later!', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false })
      });

  }

  handleReset() {

    this.setState({
      finYear: '',
      engagementTypeName: '',
      clientName: '',
      engagementTypeName: '',
      finYear: '',
      finYearList: [],
      locationList: [],
      location: '',
      auditPeriodStartDate: '',
      auditPeriodToDate: '',
      startDate: '',
      endDate: '',
      expectedClosureDate: '',
      remark: '',
      is_LocationBased: false,
      is_TimeBased: false,
      engPartnerName: '',
      qualityReviewerName: '',
      engHead: '',
      teamMemberId: '',
      checkListTemplateNameId: '',
      is_critical: false,
      isLoading: false,
      isOpen: false,
      newChecklistNameId: '',
      newChecklistNameEQ: '',
      clientEngagementId: '',
      teamMemberAndDesignationList: [],
      checkListData: [],
      engagementTypeNameList: [],
    })

  }

  // on checked critical
  handleIscritical(e, data, index) {
    const { checkListData, is_add, is_update } = this.state
    if (is_add) {
      if (e.target.checked) {
        data['IsCritical'] = !data['IsCritical']
        data['Selected'] = 1
      }
      else {
        data['IsCritical'] = !data['IsCritical']
      }
      this.setState({ checkListData: checkListData })
    }
    else if (is_update) {
      if (e.target.checked) {
        data['IsCritical'] = !data['IsCritical']
        data['Selected'] = 1
        if (data['ActionID'] == 0 && data['EngagementChecklistID'] == '') {
          data['ActionID'] = 1
        }
        if (data['ActionID'] == 0) {
          data['ActionID'] = 2
        } else if (data['ActionID'] == 3) {
          data['ActionID'] = 2
        }

      }
      else {
        data['IsCritical'] = !data['IsCritical']
        if (data['ActionID'] == 0) {
          data['ActionID'] = 2
        }
      }
      this.setState({ checkListData: checkListData })
    }

  }

  // on checked action id
  handleActionId(e, data) {
    const { checkListData, is_add, is_update } = this.state
    // add
    if (is_add) {
      if (!e.target.checked) {
        data['Selected'] = 0
        data['IsCritical'] = false
      }
      else {
        data['Selected'] = 1

      }
      this.setState({ checkListData: checkListData })
    }
    else if (is_update) {
      if (!e.target.checked) {

        data['Selected'] = 0
        data['IsCritical'] = false

        if (data['ActionID'] == 1) {
          data['ActionID'] = 0
        } else {
          data['ActionID'] = 3
        }
      }
      else {
        if (data['EngagementChecklistID'] != '') {
          data['Selected'] = 1
          data['ActionID'] = 2
        } else {
          data['Selected'] = 1
          data['ActionID'] = 1
        }

      }
      this.setState({ checkListData: checkListData })
    }
  }

  // click deselect for remove
  handleDeselect(e, data) {
    const { checkListData, is_add, is_update } = this.state
    data.Selected = !data.Selected
    this.setState({ checkListData: checkListData })
    if (is_add) {
      data['Selected'] = 1
      this.setState({ checkListData: checkListData })
    }
    else if (is_update) {
      if (data['EngagementChecklistID'] != '') {
        data['Selected'] = 1
        data['ActionID'] = 2
      } else {
        data['Selected'] = 1
        data['ActionID'] = 1
      }
      this.setState({ checkListData: checkListData })
    }
  }

  //on select fin year
  onSelectFinYearDropdown(e) {
    const { finYearList, auditPeriodStartDate, auditPeriodToDate, finYear, engagementAllocationId, clientName } = this.state
    this.setState({ finYear: e.target.value })
    finYearList.map((element, index) => {
      if (element.FinYearID == e.target.value) {
        this.setState({
          auditPeriodStartDate: moment(element.FromDate, 'YYYY-MM-DD').toDate(),
          auditPeriodToDate: moment(element.ToDate, 'YYYY-MM-DD').toDate(),
          engagementTypeNameList: []
        })

        this.getFillList(ClientEngengagementTimeSheetActionId, clientName, e.target.value)
        //  this.getFillList(ClientEngfinYearOnbasicClientActionId, data.data[0]['EngagementAllocationID'],  clientName)
      }
    })
  }

  onSelectEngHead(e) {
    const { engSelectedHeadList, engHeadList, engHead, engagementID } = this.state
    this.setState({ engHead: e.target.value != 'Select' ? e.target.value : '' })
    engHeadList.map((item, index) => {
      if (e.target.value == item.emmnID) {
        console.log(item.EngagementHead)
        var data = {
          'ActionID': 2,
          'EngMemberID': engSelectedHeadList.length > 0 ? engSelectedHeadList[0]['EngMemberID'] : "",
          'EngagementRoleID': item.desnID,
          'EngagementRoleName': item.EngagementHead,
          'TeamMemberID': item.emmnID,
        }
        engSelectedHeadList.push(data)
      }

    })
  }



  onClientNameSelected(e) {
    const { clientName, engagementAllocationId, clientNameList } = this.state
    this.setState({
      clientName: e.target.value,
      auditPeriodStartDate: '',
      auditPeriodToDate: '',
      startDate: '',
      endDate: '',
      expectedClosureDate: '',
      finYear: '',
      engagementTypeName: '',
      engPartnerName: '',
      qualityReviewerName: '',
      location: '',
      finYearList: [],
      teamMemberId: '',
      engHead: '',
      teamMemberAndDesignationList: [],
      engagementTypeNameList: [],
      is_LocationBased: false,
      is_TimeBased: false,
    })

    setTimeout(() => {
      e.target.value != '' && this.getFillList(locationActionId, e.target.value)
      console.log('ClientEngfinYearOnbasicClientActionId', ClientEngfinYearOnbasicClientActionId, e.target.value)
      e.target.value != '' && this.getFillList(ClientEngfinYearOnbasicClientActionId, e.target.value)


      clientNameList.map((item, index) => {
        if (item.ClientID == e.target.value) {
          // this.setState({ engagementAllocationId: item.EngagementAllocationID })
          // this.getFillList(ClientEngengagementTimeSheetActionId, item.ClientID)
          this.getFillList(ClientEngfinYearOnbasicClientActionId, item.ClientID)

        }
      })
    }, 100);

  }

  onEngagementTypeSelected(e) {
    const { clientName, engagementAllocationId, clientNameList, engagementTypeNameList } = this.state
    this.setState({ engagementTypeName: e.target.value })

    engagementTypeNameList.map((item, index) => {
      if (item.EngagementTypeID == e.target.value) {
        this.setState({ engagementAllocationId: item.EngagementAllocationID })
        console.log("87**************************", item.EngagementAllocationID)
        setTimeout(() => {

          this.getFillList(clientEngagementTeamMemEngPatQRActionId, item.EngagementAllocationID)

        }, 100);
        // this.getFillList(ClientEngfinYearOnbasicClientActionId, item.EngagementAllocationID)
      }
    })

  }

  // this.onChecklistTemplateNameSelect()}
  onChecklistTemplateNameSelect(e) {
    const { checkListTemplateNameId } = this.state
    this.setState({ newChecklistNameEQ: '', checkListTemplateNameId: e.target.value != null ? e.target.value : '' })
    this.getFillList(clientEngagementChecklistActionId, e.target.value)
    this.getFillList(clientEngagementNewChecklistActionId, e.target.value)
  }

  //toggle model for delete.................................
  toggleModal() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { clientNameList, clientName, is_LocationBased, is_TimeBased, location, endDate, startDate, expectedClosureDate, remark, engHead, engHeadList, teamMemberId, teamMemberList, teamMemberAndDesignationList
      , status, checkListTemplateNameList, checkListTemplateNameId, checkListData, newChecklistNameId, newChecklistNameList, newChecklistNameEQ,
      details_clientId, is_update, is_allSelected, is_allDeselected, locationList, qualityReviewerName, qualityReviewerNameList, isLoading, finYearList,
      finYear, teamDesignationDropdownId, teamDesignationDropdownList, engPartnerNameList,
      engPartnerName, engagementTypeName, engagementTypeNameList, is_add, isAnyCompleted,
      comment, isOpen, checkDesignationListCondition } = this.state

    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            <>
              {/* start page */}
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">
                      <li>
                        <a href="#">My Engagement</a>
                      </li>
                      <li />
                    </ul>
                  </div>
                  <div className="user-status">
                    {
                      is_update && status != '' ? <p>
                        <strong className={status === 'In-Process' ? 'greena' : status != '' ? 'redi' : ''}> {status}</strong>
                      </p> : <></>
                    }
                  </div>
                  <div className="row">
                    {/* <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            .select-client-box label {\n    width: 32%!important;\n}\n                                           \n    .select2-container .select2-search--inline .select2-search__field {\n    box-sizing: border-box;\n    border: none;\n    font-size: 100%;\n    margin-top: 0px;\n    border: 1px solid #fff!important;\n    padding: 0;\n    padding-left: 5px;\n}\n.select2-selection {\n    padding: 0px 0px !important;\n    height: 12px !important;\n}\n.select2-container .select2-selection--multiple {\n    box-sizing: border-box;\n    cursor: pointer;\n    display: block;\n    min-height: 32px;\n    height: auto!important;\n    user-select: none;\n    -webkit-user-select: none;\n}\n                            "
          }}
        /> */}
                    <div className="col-md-12 pg-body grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body pt-1">
                          <div className="row user-status">

                          </div>
                          <div className="col-md-12 mb-3 stretch-card">
                            <div className="" style={{ width: "100%" }}>
                              <div className="">
                                <div className="mob-tab mb-2">
                                  <div className="mob-wd1">
                                    <ul
                                      className="nav nav-tabs master-tab"
                                      id="myTab"
                                      role="tablist"
                                    >
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className="nav-link active"
                                          id="home-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#home"
                                          type="button"
                                          role="tab"
                                          aria-controls="home"
                                          aria-selected="true"
                                        >
                                          Engagement Details
                                        </button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className="nav-link"
                                          id="profile-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#profile"
                                          type="button"
                                          role="tab"
                                          aria-controls="profile"
                                          aria-selected="false"
                                        >
                                          Team Members
                                        </button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className="nav-link"
                                          id="contact-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#contact"
                                          type="button"
                                          role="tab"
                                          aria-controls="contact"
                                          aria-selected="false"
                                        >
                                          Checklist Details
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='page-scroll-from-bot-tab' style={{ height: 'calc(-150px + 92vh)', overflow: 'auto', overflowX: 'hidden' }}>
                                  <div
                                    className="tab-content" style={{ height: 'auto !important' }}>
                                    <div
                                      className="tab-pane fade active show"
                                      id="home"
                                      role="tabpanel"
                                      aria-labelledby="home-tab"
                                    >
                                      <div className="d-flex flex-wrap row">
                                        <div className="form-group col-md-6">
                                          <label>
                                            Client<span className="required1">*</span>
                                          </label>
                                          <select
                                            className={is_update ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'}

                                            style={{ width: "100%" }}
                                            value={clientName}
                                            onChange={(e) => this.onClientNameSelected(e)}
                                            disabled={is_update || isAnyCompleted}

                                          >
                                            <option value={''}>Select</option>
                                            {clientNameList.map(item => (
                                              <option
                                                key={item.ClientID}
                                                value={item.ClientID}>
                                                {item.CLIENTNAME}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-1">

                                        <div className="form-group col-md-3">
                                          <label>
                                            Financial Year<span className="required1">*</span>
                                          </label>
                                          <select
                                            className={is_update ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'}
                                            style={{ width: "100%" }}
                                            value={finYear}
                                            onChange={(e) => this.onSelectFinYearDropdown(e)}
                                            disabled={is_update || isAnyCompleted}
                                          >
                                            <option value={''}>Select</option>
                                            {finYearList.map(item => (
                                              <option
                                                key={item.FinYearID}
                                                value={item.FinYearID}>
                                                {item.FinancialYear}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label>
                                            Engagement Type
                                            <span className="required1">*</span>
                                          </label>
                                          <select
                                            className={is_update ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'}
                                            style={{ width: "100%" }}
                                            value={engagementTypeName}
                                            onChange={(e) => this.onEngagementTypeSelected(e)}
                                            // onChange={(e) => this.setState({ engagementTypeName: e.target.value })}
                                            disabled={is_update || isAnyCompleted}
                                          >
                                            <option value={''}>Select</option>
                                            {engagementTypeNameList.map(item => (
                                              <option
                                                key={item.EngagementTypeID}
                                                value={item.EngagementTypeID}>
                                                {item.EngagementTypeName}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-1">
                                        <div className="form-group col-md-3">
                                          <label>Location</label>
                                          <select
                                            // !is_LocationBased &&
                                            //locationList.length == 0 &&
                                            className={is_LocationBased == false ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'}
                                            style={{ width: "100%" }}
                                            value={is_LocationBased == false ? '':location}
                                            onChange={(e) => this.setState({ location: e.target.value })}
                                            disabled={is_LocationBased == false}
                                          >
                                            {locationList.length > 0 ? <option value={''}>Select</option> : null}
                                            {locationList.length > 0 && locationList.map(item => (
                                              <option
                                                key={item.clcnID}
                                                value={item.clcnID}>
                                                {item.LOCATIONNAME}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div
                                          className="form-group col-1 pr-0"
                                          style={{ width: "13%" }}
                                        >
                                          <div className="form-group d-flex mt-4">
                                            <label className='p-0 w-auto d-flex mr-1'>Location Based:</label>
                                            <input
                                              type="checkbox"
                                              style={{ marginTop: "-5px" }}
                                              onClick={(e) => this.setState({ is_LocationBased: locationList.length > 0 ? !is_LocationBased : false })}
                                              value={this.state.is_LocationBased}
                                              checked={this.state.is_LocationBased && locationList.length > 0}
                                              // disabled={isAnyCompleted }
                                              disabled={locationList.length == 0}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="form-group col-1 pr-0"
                                          style={{ width: "11%" }}
                                        >
                                          <div className="form-group d-flex mt-4">
                                            <label className='p-0 w-auto d-flex mr-1'>Time Based:</label>
                                            <input
                                              type="checkbox"
                                              style={{ marginTop: "-5px" }}
                                              onClick={(e) => this.setState({ is_TimeBased: !is_TimeBased })}
                                              value={is_TimeBased}
                                              checked={this.state.is_TimeBased}
                                              disabled={isAnyCompleted}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-1">
                                        <div className="form-group col-md-3">
                                          <label>
                                            Audit Period<span className="required1">*</span>
                                          </label>
                                          <div className="form-group d-flex">
                                            {/* className={is_update ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'} */}

                                            <DatePicker className={is_update ? "form-control bg-secondary" : "form-control"}
                                              dateFormat="dd-MM-yyyy"
                                              selected={this.state.auditPeriodStartDate}
                                              disabled={true}
                                              value={this.state.auditPeriodStartDate}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              // onChange={(date) => {
                                              //   this.setState({ auditPeriodStartDate: date });

                                              // }}
                                              // readOnly
                                              placeholderText="From Date"
                                              customInput={<ExampleCustomInput />}
                                              dayClassName={() => "example-datepicker-day-class"}
                                              popperClassName={is_update ? 'example-datepicker-class bg-secondary' : 'example-datepicker-class'}
                                            />
                                            {/* <FaCalendarAlt style={{
                                            flex: '0 0 19%',
                                            maxWidth: '19%',
                                            color: 'grey',
                                            marginTop: '7px',
                                            marginLeft: '-3rem',
                                            zIndex: '0'
                                          }} /> */}
                                          </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label>&nbsp; </label>
                                          <div className=' d-flex'>
                                            <DatePicker className="form-control"
                                              dateFormat="dd-MM-yyyy"
                                              selected={this.state.auditPeriodToDate}
                                              minDate={this.state.auditPeriodStartDate}
                                              disabled={true}
                                              value={this.state.auditPeriodToDate}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              // onChange={(date) => {
                                              //   this.setState({ auditPeriodToDate: date });

                                              // }}
                                              placeholderText="To Date"
                                              customInput={<ExampleCustomInput />}
                                              dayClassName={() => "example-datepicker-day-class"}
                                              popperClassName="example-datepicker-class"
                                            />
                                            {/* <FaCalendarAlt style={{
                                            flex: '0 0 19%',
                                            maxWidth: '19%',
                                            color: 'grey',
                                            marginTop: '7px',
                                            marginLeft: '-3rem',
                                            zIndex: '0'
                                          }} /> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-1">
                                        <div className="form-group col-md-3">
                                          <label>
                                            Start Date<span className="required1">*</span>
                                          </label>
                                          <div className="form-group d-flex">

                                            <DatePicker className="form-control"
                                              dateFormat="dd-MM-yyyy"
                                              selected={startDate}
                                              // minDate={this.state.auditPeriodStartDate}
                                              // maxDate={this.state.auditPeriodToDate}
                                              // minDate={new Date(Date.now() - 100 * 24 * 60 * 60 * 1000)}
                                              disabled={isAnyCompleted}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              onChange={(date) => {
                                                console.log("onChange")
                                                this.setState({ startDate: date });
                                              }}
                                              // onKeyDown={(date) => {
                                              //   console.log("onKeyDown")
                                              //   this.setState({ startDate: date });
                                              // }}
                                              placeholderText="Start Date"
                                              customInput={<ExampleCustomInput />}

                                              dayClassName={() => "example-datepicker-day-class"}
                                              popperClassName="example-datepicker-class"
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label>
                                            End Date<span className="required1">*</span>
                                          </label>
                                          <div className="form-group d-flex">
                                            <DatePicker className="form-control"
                                              dateFormat="dd-MM-yyyy"
                                              // minDate={startDate}
                                              selected={endDate}
                                              // maxDate={this.state.auditPeriodToDate}
                                              peekNextMonth
                                              disabled={isAnyCompleted}
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              onChange={(date) => {
                                                this.setState({ endDate: date });

                                              }}
                                              placeholderText="End Date"
                                              customInput={<ExampleCustomInput />}
                                              dayClassName={() => "example-datepicker-day-class"}
                                              popperClassName="example-datepicker-class"
                                            />
                                            {/* <FaCalendarAlt style={{
                                            flex: '0 0 19%',
                                            maxWidth: '19%',
                                            color: 'grey',
                                            marginTop: '7px',
                                            marginLeft: '-3rem',
                                            zIndex: '0'
                                          }} /> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-1">
                                        <div className="form-group col-md-3">
                                          <label>
                                            Expected Closure Date
                                            <span className="required1">*</span>
                                          </label>
                                          <div className="form-group d-flex">
                                            <DatePicker className="form-control"
                                              dateFormat="dd-MM-yyyy"
                                              selected={expectedClosureDate}
                                              minDate={startDate}
                                              disabled={isAnyCompleted}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              onChange={(date) => {
                                                this.setState({ expectedClosureDate: date });

                                              }}
                                              placeholderText="Expected Closure Date"
                                              customInput={<ExampleCustomInput />}
                                              dayClassName={() => "example-datepicker-day-class"}
                                              popperClassName="example-datepicker-class"
                                            />

                                            {/* <FaCalendarAlt style={{
                                            flex: '0 0 19%',
                                            maxWidth: '19%',
                                            color: 'grey',
                                            marginTop: '7px',
                                            marginLeft: '-3rem',
                                            zIndex: '0'
                                          }} /> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-1 mb-2">
                                        <div className="form-group col-md-6">
                                          <label>Remark</label>
                                          <textarea
                                            id="w3review"
                                            name="w3review"
                                            rows={2}
                                            cols={50}
                                            style={{ border: "1px solid #ddd" }}
                                            value={remark}
                                            disabled={isAnyCompleted}
                                            onChange={e => this.setState({ remark: e.target.value })}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="profile"
                                      role="tabpanel"
                                      aria-labelledby="profile-tab"
                                    >
                                      <div className="row">
                                        <div className="form-group col-md-3">
                                          <div className="form-group col-12">
                                            <form>
                                              <div className="form-group">
                                                <label>Engagement Partner</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  // placeholder="Mr. N Jayendran"
                                                  disabled="true"
                                                  value={engPartnerName}
                                                // onChange={e => this.setState({ engPartnerName: e.target.value })}
                                                />
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                        <div className="form-group col-md-3">
                                          <div className="form-group col-12">
                                            <form>
                                              <div className="form-group">
                                                <label>Quality Reviewer</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  disabled="true"
                                                  // placeholder="Mr. Shihab R Halim"
                                                  value={qualityReviewerName}
                                                // onChange={e => this.setState({ qualityReviewerName: e.target.value })}
                                                />
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row">
                                        <div className="form-group col-md-3">
                                          <label>
                                            Engagement Head{" "}
                                            <span className="required1">*</span>
                                          </label>
                                          <select
                                            className="js-example-basic-single"
                                            style={{ width: "100%" }}
                                            value={engHead}
                                            disabled={isAnyCompleted}
                                            onChange={(e) => this.onSelectEngHead(e)}

                                          >
                                            <option value={''}>Select</option>
                                            {engHeadList.map(item => (
                                              <option
                                                key={item.emmnID}
                                                value={item.emmnID}>
                                                {item.EngagementHead}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="form-group col-md-3 mt-1">
                                          <div className="form-group col-12">
                                            <form>
                                              <div className="form-group">
                                                <label>Team Member</label>
                                                <select
                                                  className="js-example-basic-single"
                                                  style={{ width: "100%" }}
                                                  disabled={isAnyCompleted}
                                                  value={teamMemberId}

                                                  onChange={(e) => this.onTeamMemberSelect(e)}
                                                >
                                                  <option value=''>Select</option>
                                                  {teamMemberList.map(item => (
                                                    <option
                                                      key={item.TeamMemberID}
                                                      value={item.TeamMemberID}>
                                                      {item.TeamMember}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-wrap row mt-2">
                                        <div className="form-group col-12">

                                          <div
                                            className="table-responsive tbs4 common-table trail-tb"
                                            style={{ width: "49%" }}
                                          >
                                            <table className="table table-bordered tb-action du-table">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: 20 }} />
                                                  <th style={{ width: 150 }}>Team Member</th>
                                                  <th style={{ width: 100 }}>Designation</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  teamMemberAndDesignationList.length > 0 ? (
                                                    teamMemberAndDesignationList.map((item) => (
                                                      <tr key={Math.random()}>
                                                        <td className="text-center">
                                                          {!isAnyCompleted && <i className="fa fa-trash-o text-danger" onClick={(e) => this.onRemoveTeamMember(item.TeamMemberID)} />}

                                                        </td>
                                                        <td>{item.TeamMember}</td>
                                                        <td className='p-0'>{
                                                          checkDesignationListCondition.includes(item.EngRoleName)
                                                            // (item.EngRoleName) == "Audit Assistant "
                                                            //   || item.EngRoleName == "CA - Employee"
                                                            //   || item.EngRoleName == "CA - Partner"
                                                            //   || item.EngRoleName == "Semi-qualified CA"
                                                            //   || item.EngRoleName == "Independent Peer Reviewer"
                                                            //   || item.EngRoleName == "Engagement Manager"
                                                            || item.EngagementRoleID == '0'
                                                            // || item.EngagementRoleID == '5'
                                                            ?
                                                            <select
                                                              className="js-example-basic-single"
                                                              style={{ width: "100%" }}
                                                              value={item.EngRoleName}
                                                              // onClick={(e) => this.addOnTeamDesignationDropdownList(item.TeamMemberID)}
                                                              onChange={(e) => this.onSelectTeamDesignationDropdownList(e.target.value, item, item.EngagementRoleID)}
                                                            >
                                                              {/* <option 
                                                                value={item.EngRoleName}>
                                                                {item.EngRoleName}
                                                                select
                                                              </option> */}
                                                              {item.arr.map(data => (
                                                                <option
                                                                  key={data.Code}
                                                                  value={data.Description}>
                                                                  {data.Description}
                                                                </option>
                                                              ))}
                                                            </select> :
                                                            item.EngRoleName

                                                        }</td>
                                                      </tr>
                                                    ))


                                                  ) : (<></>)
                                                }


                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade wm-forms"
                                      id="contact"
                                      role="tabpanel"
                                      aria-labelledby="contact-tab"
                                    >
                                      <div className="d-flex flex-wrap row">
                                        <div className="form-group col-md-3">
                                          <label>
                                            Checklist Template{" "}
                                            <span className="required1">*</span>
                                          </label>
                                          <select
                                            className={is_update ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'}

                                            // className="js-example-basic-single"
                                            style={{ width: "100%" }}
                                            value={checkListTemplateNameId}
                                            onChange={(e) => this.onChecklistTemplateNameSelect(e)}
                                            disabled={is_update || isAnyCompleted}
                                          >
                                            <option value={''}>Select</option>
                                            {checkListTemplateNameList.map(item => (
                                              <option
                                                key={item.ChecklistTemplateHdrID}
                                                value={item.ChecklistTemplateHdrID}>
                                                {item.ChecklistTemplateName}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        {/* <div class="col-md-1 mt-3">
                                                      <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><span class="add-fil">+</span></a>
                                                  </div> */}
                                      </div>
                                      {checkListTemplateNameId != '' && <div className="d-flex flex-wrap row mt-2">
                                        <div
                                          className="table-responsive tbs4 common-table trail-tb"
                                          style={{ width: "65.6%" }}
                                        >
                                          <table className="table table-bordered tb-action du-table">
                                            <thead>
                                              <tr>
                                                <th style={{ width: 60 }}>Sr No</th>
                                                <th>Checklist</th>
                                                <th style={{ width: 150 }}>E/Q</th>
                                                <th
                                                  className="text-center"
                                                  style={{ width: 100 }}
                                                >
                                                  Critical
                                                </th>
                                                <th>Un-Check For Remove</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                checkListData.length > 0 ? (
                                                  checkListData.map((item, index) => (
                                                    <tr>
                                                      <td>{index + 1}</td>
                                                      <td>{item.ChecklistName}</td>
                                                      <td>{item.Name}</td>
                                                      <td className="text-center">
                                                        <input type="checkbox" checked={item.IsCritical}
                                                          disabled={isAnyCompleted} onClick={(e) => this.handleIscritical(e, item)} />
                                                      </td>
                                                      <td className='text-center'>
                                                        {
                                                          item.Selected ?
                                                            <input type="checkbox" checked={item.Selected} disabled={isAnyCompleted}
                                                              onClick={(e) => this.handleActionId(e, item)} /> :
                                                            <i className='fa fa-remove' onClick={e => this.handleDeselect(e, item)}></i>
                                                        }


                                                      </td>
                                                    </tr>
                                                  ))
                                                ) : (<tr><td colSpan={5} className='text-center'>No record found</td></tr>)
                                              }


                                              <tr>
                                                <td />
                                                <td style={{ padding: 0 }}>
                                                  <select
                                                    className="js-example-basic-single"
                                                    style={{
                                                      width: "100%",
                                                      height: 38,
                                                      border: "none",
                                                      background: "#eef7f9"
                                                    }}
                                                    value={newChecklistNameId}
                                                    disabled={isAnyCompleted}
                                                    onChange={e => this.onSelectNewChecklistName(e, newChecklistNameId)}
                                                  >
                                                    <option>Add more checklist</option>
                                                    {newChecklistNameList.map(item => (
                                                      <option
                                                        key={item.ChecklistID}
                                                        value={item.ChecklistID}>
                                                        {item.ChecklistName}
                                                      </option>
                                                    ))}
                                                  </select>
                                                </td>
                                                <td style={{ padding: 0 }}>
                                                  <input
                                                    type="text"
                                                    style={{
                                                      padding: 7,
                                                      background: "#eef7f9"
                                                    }}
                                                    disabled={true}
                                                    value={newChecklistNameEQ}
                                                  />
                                                </td>
                                                <td className="text-center">
                                                  <input type="checkbox" />
                                                </td>
                                                <td className="text-center">
                                                  <input type="checkbox" />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>}
                                      <div className="d-flex flex-wrap row mt-3 mb-2">
                                        <div className="form-group col-md-6">
                                          <label>Comment</label>
                                          <textarea
                                            className='h-auto form-control'
                                            id="Comment"
                                            name="Comment"
                                            rows={2}
                                            cols={50}
                                            value={comment}
                                            disabled={isAnyCompleted}
                                            onChange={e => this.setState({ comment: e.target.value })}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div style={{ height: '400px' }}></div> */}
                                </div>
                                {/* <div style={{ height: '250px' }}></div> */}
                              </div>
                              <div className="row">
                                <div className="footer-btn">
                                  <div className="col-md-6">
                                    <div
                                      className="btn-group"
                                      role="group"
                                      aria-label="Basic example"
                                    >
                                      {
                                        is_update && isAnyCompleted ? " " :
                                          <>
                                            {is_add ?
                                              getModuleWriteAccess(Client_Engagement) && <button
                                                type="button"
                                                className="btn btn-save footer-btn-inner mr-1"
                                                onClick={this.handleSaveAndApprove}
                                              >
                                                Save & Approve
                                              </button>
                                              :
                                              getModuleUpdateAccess(Client_Engagement) && <button
                                                type="button"
                                                className="btn btn-save footer-btn-inner mr-1"
                                                onClick={this.handleSaveAndApprove}
                                              >
                                                Update
                                              </button>
                                            }
                                          </>
                                      }


                                      {
                                        is_add && getModuleWriteAccess(Client_Engagement) && <button
                                          type="button"
                                          className="btn btn-update footer-btn-inner mr-1"
                                          onClick={this.handleReset}
                                        >
                                          Reset
                                        </button>
                                      }
                                      {
                                        is_update && <button
                                          type="button"
                                          className="btn btn-print-audit footer-btn-inner mr-1"
                                          onClick={e => this.handleDownload(e)}
                                        >
                                          Download Audit Plan
                                        </button>
                                      }
                                      {/* <button type="button" class="btn btn-delete footer-btn-inner mr-1">Delete</button> */}
                                      <button
                                        type="button"
                                        className="btn btn-cancel footer-btn-inner"
                                        onClick={this.handleCancel}

                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>


          </div>
        </div>
        <ToastContainer />
        <Modal
          isOpen={isOpen}
          onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header">
                <h4 class="modal-title modal_title_text">Email</h4>
                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: !isOpen })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="pop_label">Do you want to send the email?</h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group text-right mod-fot" >
                    <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.onSaveAndMail(e, true)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                    <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={(e) => this.onSaveAndMail(e, false)}><i class="fa fa-times"></i>&nbsp; No</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(connect(null, {
  fill_action,
  clientEngagementDetails_action,
  updateClientEngagement_action,
  addClientEngagement_action,
  downloadPlane_action,
  designationDropdownList_action
})(ClientEngagementAU));