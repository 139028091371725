import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
    addExpense_action, updateExpense_action, expenseDetails_action, approvalExpense_action, getTimesheetId_action, deleteExpenseDocument_action
} from '../../../actions/time_sheet_expense_action/expense_action'
import { LastTimesheetFetch_action } from '../../../actions/time_sheet_expense_action/time_sheet_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
    TimeSheetClientActionId, financialYearId, engagementPartnerId,
    engagementTypeId, transcationForActionId, employeeActionId,
    taskActionId, transcationTypeActionId,
    finYearOnbasicClientActionId, engagementTimeSheetActionId, expenseTypeActionId,
    Expense,
} from '../../../utils/constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt, FaRegFolderOpen } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import TimeField from 'react-simple-timefield';
import { data } from 'jquery';
import Resizer from "react-image-file-resizer";
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class ExpenseAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: '',
            isOpen: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            is_Expenseupdate: false,
            isLoading: false,
            transactionForId: '',
            transactionForList: [],
            finYearId: '',
            financialYearList: [],
            clientNameList: [],
            clientNameId: '',
            engagementId: '',
            engagementList: [],
            expenseTypeId: '',
            expenseTypeList: [],
            employeeId: (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            employeeList: [],
            taskId: '',
            taskList: [],
            description: '',
            date: '',
            amount: '',
            remark: '',
            rejectionReason: '',
            isPaidByCreditCard: false,
            isNeedSupporting: false,
            timeSheetExpenseID: '',
            expenseHeadDescription: '',
            approvedBy: '',
            expenseId: '',
            documentFilesList: [],
            pickedFileList: [],
            expDocumentDeleteId: '',
            newTimeSheetID: '',
            UpdateTimeSheetExpenseID: '',
            expenseDisable: false,
            navigateTimesheetAdd: false,
            isClientDisable: false,
            timesheetDisable: false,
            startDate: '',
            endDate: '',
            is_DateFlag: false,
            startDate: '',
            allowedFileTypes: [],
            isOpenDeleteFile: false,
            expDocumentDeleteId: '',
            expDocumentDeleteId2: '',
            isDatePresent: false,
            currentDate: new Date,

        }
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onSave = this.onSave.bind(this)
        this.toCamelCase = this.toCamelCase.bind(this)
        this.onSelectClient = this.onSelectClient.bind(this)
        this.onSelectFinYear = this.onSelectFinYear.bind(this)
        this.getDetailsExpense = this.getDetailsExpense.bind(this)
        this.getTimesheetId = this.getTimesheetId.bind(this)
        this.approvalExpense = this.approvalExpense.bind(this)
        this.handleTransactionForId = this.handleTransactionForId.bind(this)
        this.onSelectEngagementId = this.onSelectEngagementId.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        // this.handleRemoveFile = this.handleRemoveFile.bind(this)
        this.fileInputRef = React.createRef();
        this.toggleModalDeleteFile = this.toggleModalDeleteFile.bind(this)
    }

    componentDidMount() {


        this.getFillList(transcationForActionId, '')
        this.getFillList(employeeActionId, '')
        this.getFillList(TimeSheetClientActionId, '')
        this.getFillList(taskActionId, '')
        this.getFillList(transcationTypeActionId, '')
        this.getFillList(expenseTypeActionId, '')

        if (this.props.location.state != null &&
            this.props.location.state.timeSheetExpenseID != null && this.props.location.state.is_navigateTimesheetToExpense && this.props.location.state.clientNameId != '' && this.props.location.state.finYearId != '' && this.props.location.state.engagementId != '') {
            this.getFillList(finYearOnbasicClientActionId, this.props.location.state.clientNameId, this.props.location.state.engagementId)
            this.getFillList(engagementTimeSheetActionId, this.props.location.state.clientNameId, this.props.location.state.finYearId)
        }

        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_Expenseupdate: this.props.location.state != null && this.props.location.state.is_Expenseupdate != null ? this.props.location.state.is_Expenseupdate : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            engagementAllocationId: this.props.location.state != null && this.props.location.state.engagementAllocationId != null ? this.props.location.state.engagementAllocationId : '',
            isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,

            clientNameId: this.props.location.state != null && this.props.location.state.clientNameId != null ? this.props.location.state.clientNameId : '',
            transactionForId: this.props.location.state != null && this.props.location.state.transactionForId != null ? this.props.location.state.transactionForId : '',
            finYearId: this.props.location.state != null && this.props.location.state.finYearId != null ? this.props.location.state.finYearId : '',
            engagementId: this.props.location.state != null && this.props.location.state.engagementId != null ? this.props.location.state.engagementId : '',
            date: this.props.location.state != null && this.props.location.state.date != null ? this.props.location.state.date : '',

            timeSheetExpenseID: this.props.location.state != null && this.props.location.state.timeSheetExpenseID != null ? this.props.location.state.timeSheetExpenseID : '',

            UpdateTimeSheetExpenseID: this.props.location.state != null && this.props.location.state.UpdateTimeSheetExpenseID != null ? this.props.location.state.UpdateTimeSheetExpenseID : '',


        })
        console.log("this.props", this.props)
        setTimeout(() => {
            this.props.location.state != null && this.props.location.state.is_update && this.getDetailsExpense()
        }, 100);

        this.props.location.state != null && this.props.location.state.is_navigateTimesheetToExpense ? this.setState({ navigateTimesheetAdd: true }) : <></>
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        // allowedFileTypes: ['.pdf', '.jpeg', '.png', '.jpg', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']

        this.setState({
            allowedFileTypes: ['.pdf', '.jpeg', '.png', '.jpg',]
        })
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get Quality Reviewer Name List dropdown list 
    getFillList(value, ref, ref2) {
        const { clientNameId, is_add } = this.state
        this.setState({ isLoading: true })

        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == engagementTimeSheetActionId) {

                        console.log("****")
                        this.setState({ engagementList: data.data, isLoading: false })

                        if (data.data.length == 1) {
                            this.setState({ is_DateFlag: false })

                        }

                        if (data.data.length == 0) {
                            toast.error('No engagement created  against this financial year ', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                        if (data.data.length == 1) {
                            this.setState({ engagementId: data.data[0]['EngagementID'], isLoading: false })
                            sessionStorage.setItem('engagementIdTimeSS', data.data[0]['EngagementID'])
                            console.log("this.state.engagementId11****", this.state.engagementId)
                            this.getStartEndDateList(data.data[0]['EngagementID'])
                            this.lastTimesheetFill(data.data[0]['EngagementID'])

                        }
                        console.log("engagementTimeSheetActionId", data.data)
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);



                    }
                    if (value == TimeSheetClientActionId) {

                        this.setState({ clientNameList: data.data, isLoading: false })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);
                    }
                    if (value == finYearOnbasicClientActionId) {
                        this.setState({ financialYearList: data.data })
                        if (data.data.length == 0) {
                            toast.error('No financial year available against this client', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })

                            // is_add && setTimeout(() => {
                            //     this.getFillList(engagementTimeSheetActionId, clientNameId, data.data[0]['FinYearID'])
                            // }, 500);
                        }
                        if (data.data.length == 1) {
                            this.setState({ finYearId: data.data[0]['FinYearID'] })

                            console.log('')
                            setTimeout(() => {
                                this.getFillList(engagementTimeSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'])
                            }, 200);
                        }


                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);
                    }

                    if (value == taskActionId) {
                        console.log('employeeActionId')

                        this.setState({ taskList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);
                    }
                    if (value == transcationForActionId) {
                        console.log('employeeActionId')

                        this.setState({ transactionForList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);
                    }
                    if (value == expenseTypeActionId) {

                        this.setState({ expenseTypeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);
                    }
                    if (value == employeeActionId) {
                        console.log('employeeActionId ????????', localStorage.getItem('employeeIdSS'))
                        data.data.map(item => {
                            if (item.emmnID == localStorage.getItem('employeeIdSS')) {
                                console.log('employeeActionId ????????', localStorage.getItem('employeeIdSS'))

                                this.setState({ employeeId: item.emmnID })
                            }
                        })
                        this.setState({ employeeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 200);
                    }
                    this.setState({ isLoading: false })


                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    onSelectTransactionForId(e) {
        const { is_add, date, currentDate } = this.state
        this.setState({ transactionForId: e.target.value })
        if (e.target.value === '2') {
            this.setState({
                isClientDisable: true,
                clientNameId: '',
                finYearId: '',
                engagementId: '',
                // endDate: '',
                // startDate: '',
                // lastDateTimesheetFill: '',
                date: is_add ? '' : date,
                endDate: currentDate
            })
        } else {
            // sessionStorage.setItem('transactionForIdTimeSS', e.target.value)
            this.setState({ isClientDisable: false, date: is_add ? '' : date, })
        }
    }

    onSelectEngagementId(e) {
        const { is_add, date } = this.state
        this.setState({ engagementId: e.target.value, is_DateFlag: false })
        // sessionStorage.setItem('engagementIdTimeSS', e.target.value)
        e.target.value != '' ? this.setState({ date: is_add ? '' : date }) : <></>
        setTimeout(() => {
            e.target.value != '' ? this.getStartEndDateList() : <></>
            e.target.value != '' && is_add ? this.lastTimesheetFill() : <></>
        }, 100);
    }

    onSelectDate(date) {
        const { } = this.state
        this.setState({ date: date })
        // sessionStorage.setItem('dateTimeSS', date)

    }

    getStartEndDateList(engId) {
        const { engagementId, singleDisableLastDateTimesheet, currentDate, is_update, date } = this.state
        console.log("123", engagementId)
        this.setState({ isLoading: true })
        this.props
            .LastTimesheetFetch_action(engId != null ? engId : engagementId)
            .then((data) => {
                if (data.error != 1) {

                    const strDate = data.data[0]['StartDate'] != '' ? moment(data.data[0]['StartDate'], 'YYYY-MM-DD').toDate() : null
                    const endDate = data.data[0]['EndDate'] != '' ? moment(data.data[0]['EndDate'], 'YYYY-MM-DD').toDate() : null

                    if (is_update) {
                        console.log('date', date);
                        console.log('strDate', strDate);
                        console.log('endDate', endDate);
                        // Compare if myDate is between strDate and endDate
                        if (date >= strDate && date <= endDate) {
                            console.log('Date is between strDate and endDate');
                            // toast.error('Timesheet Date not in range of engagement date.', {
                            //     theme: 'colored',
                            //     autoClose: 3000,
                            //     hideProgressBar: true,
                            // })
                        } else {
                            console.log('Date is not between strDate and endDate');
                            toast.error('Timesheet Date not in range of engagement date.', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    }


                    if (singleDisableLastDateTimesheet >= strDate && singleDisableLastDateTimesheet >= endDate) {
                        this.setState({ isDatePresent: true })
                    } else {
                        this.setState({ isDatePresent: false })
                    }

                    if (currentDate < endDate) {
                        this.setState({
                            startDate: strDate,
                            endDate: currentDate
                        })
                    } else {
                        this.setState({
                            startDate: strDate,
                            endDate: endDate
                        })
                    }

                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    lastTimesheetFill(engId) {
        const { engagementId } = this.state
        console.log("engId", engId)
        console.log("engagementId", engagementId)
        this.setState({ isLoading: true })
        this.props
            .lastTimesheetFill_action(engId != null ? engId : engagementId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("last timesheet fill", data.data)

                    this.setState({
                        disableLastDateTimesheetFill: data.data != '' ? moment(data.data[0]['Nextdate'], 'YYYY-MM-DD').toDate() : '', isLoading: false,
                        lastDateTimesheetFill: data.data != '' ? moment(data.data[0]['timesheetdate']).format('DD-MM-YYYY') : '',
                    })

                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    // getStartEndDateList(engId) {
    //     const { engagementId } = this.state
    //     console.log("123", engagementId)
    //     this.setState({ isLoading: true })
    //     this.props
    //         .LastTimesheetFetch_action(engId != null ? engId : engagementId)
    //         .then((data) => {
    //             if (data.error != 1) {
    //                 this.setState({
    //                     startDate: data.data[0]['StartDate'] != '' ? moment(data.data[0]['StartDate'], 'YYYY-MM-DD').toDate() : null,
    //                     endDate: data.data[0]['EndDate'] != '' ? moment(data.data[0]['EndDate'], 'YYYY-MM-DD').toDate() : null
    //                 })
    //                 setTimeout(() => {
    //                     this.setState({ isLoading: false })
    //                 }, 300);
    //             }
    //             else {
    //                 this.setState({ isLoading: false })

    //                 toast.error(data.msg, {
    //                     theme: 'colored',
    //                     autoClose: 3000,
    //                     hideProgressBar: true,
    //                 })
    //             }
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
    // }

    approvalExpense() {
        const { transactionForId, employeeId, clientNameId, finYearId,
            engagementId, } = this.state
        this.setState({ isLoading: true })
        this.props
            .approvalExpense_action(transactionForId, employeeId, clientNameId, finYearId, engagementId)
            .then((data) => {
                if (data.error != 1) {
                    // console.log("getTimesheetId_action ", data.data[0]['FinYearID'])

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //get TimesheetId
    getTimesheetId() {
        const { transactionForId, employeeId, clientNameId, finYearId, engagementId, is_add } = this.state
        if (transactionForId != '' && transactionForId != 'Select' &&
            employeeId != '' && employeeId != 'Select' &&
            clientNameId != '' && clientNameId != 'Select' &&
            finYearId != '' && finYearId != 'Select' &&
            engagementId != '' && engagementId != 'Select'
        ) {
            console.log("getTimesheetId 12")

            this.setState({ isLoading: true })
            this.props
                .getTimesheetId_action(transactionForId, clientNameId, finYearId, engagementId, employeeId)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("getTimesheetId_action vvddss ", data)
                        this.setState({ newTimeSheetID: data.data[0]['TIMESHEETID'], isLoading: false })
                        console.log("data.data[0]['TIMESHEETID']+++++++", data.data[0]['TIMESHEETID'])
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    handleDownload(base64data, fileName) {

        const Data = base64data.split(';')
        const fileNameData = fileName.split('.')
        // Create a virtual link element
        const downloadLink = document.createElement('a');

        // Set the href attribute to the data URI
        downloadLink.href = base64data;

        // Set the download attribute with the desired file name
        if (Data[0] == 'data:application/pdf') {
            downloadLink.download = `${fileNameData[0]}.${fileNameData[1]}`;
        } else {
            downloadLink.download = `${fileNameData[0]}.${fileNameData[1]}`;
        }

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
    };

    // get timesheet details
    getDetailsExpense() {
        this.setState({ isLoading: true })
        const { pickedFileList, UpdateTimeSheetExpenseID, newTimeSheetID, documentFilesList, is_Expenseupdate } = this.state
        if (is_Expenseupdate == true) {
            var id = UpdateTimeSheetExpenseID
        }
        else {
            var id = this.props.location.state != ' ' && this.props.location.state.expenseListId != ' ' ? this.props.location.state.expenseListId : ''
        }
        console.log("timeSheetExpenseID)___", UpdateTimeSheetExpenseID)

        // var id = timeSheetExpenseID
        // console.log("sagar(****)", timeSheetExpenseID)
        this.props
            .expenseDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data[0]['ClientID'] != 0 && data.data[0]['EngagementID'] != 0 && data.data[0]['FinYearID'] != 0) {
                        this.getFillList(finYearOnbasicClientActionId, data.data[0]['ClientID'], data.data[0]['EngagementID'])
                        this.getFillList(engagementTimeSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'])
                    }

                    data.data[0]['fileData'].length > 0 && data.data[0]['fileData'].map(data => {
                        if (data.name != null) {
                            documentFilesList.push({
                                'File': data.File,
                                'FileName': data.name,
                                'ExpDocumentID': data.ExpDocumentID,
                                'actionId': 0,
                            })
                        }
                    })

                    this.setState({
                        clientNameId: data.data[0]['ClientID'],
                        transactionForId: data.data[0]['TransactionForID'],
                        finYearId: data.data[0]['FinYearID'],
                        engagementId: data.data[0]['EngagementID'],
                        employeeId: data.data[0]['EmployeeID'],
                        description: data.data[0]['ExpenseDescription'],
                        isClientDisable: data.data[0]['TransactionForID'] == 2 ? true : false,
                        remark: data.data[0]['Remark'],
                        date: data.data[0]['ExpenseDate'] != '' && new Date(moment(data.data[0]['ExpenseDate']).format('YYYY-MM-DD')),
                        rejectionReason: data.data[0]['RejectReason'],
                        timeSheetExpenseID: data.data[0]['TimeSheetID'],
                        expenseId: data.data[0]['ExpenseID'],
                        amount: data.data[0]['Amount'],
                        expenseTypeId: data.data[0]['ExpenseHeadID'],
                        isNeedSupporting: data.data[0]['NeedSupporting'],
                        isPaidByCreditCard: data.data[0]['PaidBy'],
                        ExpenseStatus: data.data[0]['ExpenseStatus'],
                        pickedFileList: documentFilesList,
                        documentFilesList: documentFilesList,

                        isLoading: false,
                    },()=>this.getStartEndDateList(data.data[0]['EngagementID']))
                    // console.log("data.data[0]['EngagementID']", data.data[0]['EngagementID'])
                    

                    if (data.data[0]['ExpenseStatus'] == "Approved") {
                        this.setState({ expenseDisable: true })
                    }


                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // delete file
    toggleModalDeleteFile = (e, expDocumentDeleteId, id2) => {
        const { isOpenDeleteFile } = this.state;
        this.setState({
            isOpenDeleteFile: !isOpenDeleteFile,
            expDocumentDeleteId: expDocumentDeleteId,
            expDocumentDeleteId2: id2
        }); // Reset if the clicked element is not a target

        // this.handleDeleteFile(item)
    }

    onSelectClient(e) {
        const { financialYearList, date, is_add } = this.state
        console.log("e", e.target.value)
        if (e.target.value != '') {

            this.setState({ clientNameId: e.target.value }, () => { })
            setTimeout(() => {
                this.getFillList(finYearOnbasicClientActionId, e.target.value)
                // sessionStorage.setItem('clientNameIdTimeSS', e.target.value)
            }, 200);
            this.setState({
                clientNameId: e.target.value,
                date: is_add ? '' : date,
                finYearId: '',
                financialYearList: [],
                engagementId: '',
                engagementList: [],
            })
        }
        else {
            this.setState({
                clientNameId: e.target.value,
                date: is_add ? '' : date,
                finYearId: '',
                financialYearList: [],
                engagementId: '',
                engagementList: [],
            })
        }
    }

    onSelectFinYear(e) {
        const { clientNameId, timeSheetExpenseID } = this.state
        // console.log("onSelectFinYear", `${clientNameId}-${e.target.value}`)
        this.setState({ finYearId: e.target.value })
        if (e.target.value != "") {
            timeSheetExpenseID == '' && this.getFillList(engagementTimeSheetActionId, clientNameId, e.target.value)
        } else {
            this.setState({ engagementId: '', engagementList: [] })
        }


    }

    handleTransactionForId(e) {
        this.setState({ transactionForId: e.target.value })
    }

    expenseDeleteDocumentId(e, expDocumentDeleteId, id2) {
        console.log("1122**", id2)
        e.preventDefault()
        const { documentFilesList, pickedFileList } = this.state
        console.log("pickedFileList11223344", pickedFileList)
        // this.setState({ isLoading: true })

        var List = []
        const pickedFileListfilterList = pickedFileList.filter((item, index) => {
            return index != id2
        });
        console.log("pickedFileList****", pickedFileListfilterList)
        console.log("pickedFileList****", pickedFileListfilterList.length)

        const documentFilesListfilterList = documentFilesList.filter((item, index) => {
            return index != id2
        });
        console.log("documentFilesListfilterList****", documentFilesListfilterList)
        console.log("documentFilesListfilterList****", documentFilesListfilterList.length)

        this.setState({
            pickedFileList: pickedFileListfilterList,
            documentFilesList: documentFilesListfilterList,
            isOpenDeleteFile: false
        })

        var id = expDocumentDeleteId
        if (this.props.location.state.is_update == true) {
            this.props
                .deleteExpenseDocument_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data deleted successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        // this.setState({ isLoading: false })
                        // this.getDetailsExpense()
                        // pickedFileList: [], documentFilesList: [] ,
                        this.setState({ isLoading: false, isOpenDeleteFile: false })
                        this.setState({
                            pickedFileList: [...new Set(pickedFileListfilterList)],
                            documentFilesList: [...new Set(documentFilesListfilterList)]
                        })


                        // setTimeout(() => {
                        //     this.getDetailsExpense()
                        // }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {

        }


    }

    // onSelectEngagementId(e) {
    //     this.setState({ engagementId: e.target.value })
    //     // setTimeout(() => {
    //     //     this.getTimesheetId()
    //     // }, 500);
    // }

    toCamelCase(str) {
        return str.replace(/[-_]+/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (match, index) {
                return index === 0 ? match.toUpperCase() : match.toLowerCase();
            })
            .replace(/\s+/g, '');
    }

    onSave() {
        // const { timeSheetID, transactionForId, employeeId, clientNameId, finYearId,
        //     engagementId, date, description,
        //     remark, rejectionReason, expenseTypeID, expenseHeadDescription,
        //     amount, approvedBy, isNeedSupporting, isPaidByCreditCard, documentFilesList } = this.state
        const { timeSheetExpenseID, expenseTypeId, description, amount, remark, isNeedSupporting, isPaidByCreditCard, documentFilesList, transactionForId, clientNameId, finYearId, engagementId, date, employeeId } = this.state
        console.log("onSave date -------------------", date)

        var finalDocList = []
        for (var i = 0; i < documentFilesList.length; i++) {
            if (documentFilesList[i]['actioId'] == 1) {
                finalDocList.push(documentFilesList[i])
            }
        }

        if (
            (date != "" && date != null && date != "Invalid Date")
            && description != "" && (transactionForId != '2' ? (clientNameId != '' && clientNameId != 'Select' && finYearId != '' && finYearId != 'Select' && engagementId != '' && engagementId != 'Select') : true) && transactionForId != '' && transactionForId != 'Select' && amount != "" && expenseTypeId != '' && expenseTypeId != 'Select'
        ) {
            if (isNeedSupporting && finalDocList.length == 0) {
                toast.error('Please select document file', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
            }
            else {
                this.setState({ isLoading: true })
                this.props
                    .addExpense_action(timeSheetExpenseID, expenseTypeId, description, amount, remark, isNeedSupporting, isPaidByCreditCard, employeeId, transactionForId, clientNameId, finYearId, engagementId, date, finalDocList)
                    .then((data) => {
                        console.log("data 090909", data)
                        if (data.error == 0) {
                            console.log("data 090909 45", data)
                            this.setState({ isLoading: false })
                            // sessionStorage.setItem("ExpenseIDSS", data.data[0]['ExpenseID'] )
                            toast.success('Record saved successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                            setTimeout(() => {
                                this.props.navigate('/expense', { state: { is_add: true, expenseID: data.data[0]['ExpenseID'] } })
                            }, 1000);
                        }
                        else {
                            this.setState({ isLoading: false })
                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }

        } else {
            this.setState({ isLoading: false })
            toast.error('Please enter mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    // update template
    handleUpdate(e) {
        e.preventDefault()
        const { transactionForId, employeeId, clientNameId, finYearId, startDate, endDate, isClientDisable,
            engagementId, expenseTypeId, description, date,
            remark, rejectionReason, timeSheetExpenseID, expenseTypeID, expenseHeadDescription,
            amount, approvedBy, isNeedSupporting, isPaidByCreditCard, documentFilesList } = this.state
        var id = this.props.location.state != ' ' && this.props.location.state.expenseListId != ' ' ? this.props.location.state.expenseListId : ''
        console.log("onSave documentFilesList", documentFilesList)
        var finalDocList = []
        for (var i = 0; i < documentFilesList.length; i++) {
            if (documentFilesList[i]['actioId'] == 1) {
                finalDocList.push(documentFilesList[i])
            }
        }
        var list = []
        if (id != "" &&
            (date != "" && date != null && date != "Invalid Date")
            &&
            description != "" && amount != ""
        ) {
            if (isNeedSupporting && finalDocList.length == 0 && documentFilesList.length == 0) {
                toast.error('Please select document file', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
            }
            else {


                var isFollow = false;
                console.log("isClientDisable :", isClientDisable)
                console.log("isFollow 1:", isFollow)
                if (!isClientDisable) {
                    // Compare if myDate is between strDate and endDate
                    console.log('Date, startDate, endDate', date, ',', startDate, ',', endDate);
                    if (date >= startDate && date <= endDate) {
                        console.log('Date is between strDate and endDate');
                        isFollow = true
                    } else {
                        isFollow = false
                        console.log('Date is not between strDate and endDate');
                        if (!isClientDisable) {
                            toast.error('Timesheet Date not in range of engagement date.', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    }
                } else {
                    isFollow = true
                }
                console.log("isFollow 2:", isFollow)
                if (isFollow) {

                    this.setState({ isLoading: true })
                    // console.log("expense type=>", expenseTypeId)
                    this.props
                        .updateExpense_action(id, transactionForId, employeeId, clientNameId, finYearId,
                            engagementId, timeSheetExpenseID, expenseTypeId, date, expenseHeadDescription, description,
                            remark, amount, approvedBy, rejectionReason, isPaidByCreditCard, isNeedSupporting, finalDocList)
                        .then((data) => {
                            if (data.error == 0) {
                                this.setState({ isLoading: false })
                                console.log('updated*(()');
                                toast.success('Record updated successfully', {
                                    theme: 'colored',
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                })
                                setTimeout(() => {
                                    this.props.navigate('/expense')
                                }, 1000);
                            }
                            else {
                                this.setState({ isLoading: false })
                                toast.error(data.msg, {
                                    theme: 'colored',
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                })
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }



            }

        } else {
            this.setState({ isLoading: false })
            toast.error('Please enter mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    // clear field ,list
    handleCancel() {
        this.props.navigate('/expense')
    }

    handleReset() {
        this.setState({
            transactionForId: '',
            finYearId: '',
            clientNameId: '',
            engagementId: '',
            expenseTypeId: '',
            taskId: '',
            description: '',
            date: '',
            amount: '',
            remark: '',
            rejectionReason: '',
            isPaidByCreditCard: false,
            isNeedSupporting: false,
            timeSheetExpenseID: '',
            expenseTypeID: '',
            expenseHeadDescription: '',
            approvedBy: '',
            expenseId: '',
            documentFilesList: [],
            ExpenseStatus: '',
            financialYearList: [],
            engagementList: [],
        })

    }

    //toggle model for delete.................................
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // handleRemoveFile(id) {
    //     const { documentFilesList, pickedFileList } = this.state
    //     var filteredList = pickedFileList.filter((data, index) => index != id)
    //     this.setState({ pickedFileList: filteredList })
    // }

    // Original Code --------------*******---------
    // onFilePick = async (e, file) => {
    //     e.preventDefault()
    //     console.log('actual file is : ', file);
    //     const { documentFilesList, pickedFileList } = this.state
    //     var pickFileList = [...pickedFileList]
    //     var docFilesList = [...documentFilesList]
    //     for (let i = 0; i < file['length']; i++) {
    //         if (file[i]['size'] >= 5 * 1024 * 1024) {
    //             toast.error(`${file[0]['name']} size more than 5mb are not allow.`, {
    //                 theme: 'colored',
    //                 autoClose: 3000,
    //                 hideProgressBar: true,
    //             })
    //         }
    //         else {
    //             var fileMap = {
    //                 'File': file[i],
    //                 'name': file[i]['name'],
    //                 'ExpDocumentID': null,
    //                 'actionId': 1,
    //             }
    //             pickFileList.push(fileMap)
    //         }
    //     }


    //     this.setState({ pickedFileList: pickFileList })

    //     console.log("pickedFileList 1", pickFileList)
    //     // console.log("pickedFileList 2", pickedFileList[0])
    //     // console.log("pickedFileList 3", pickedFileList[0]['name'])


    //     for (let i = 0; i < pickFileList.length; i++) {
    //         console.log("0909 pickedFileList[i]", pickFileList[i])
    //         if (pickFileList[i]['actionId'] == 1 && pickFileList[i]['ExpDocumentID'] == null) {
    //             var file2 = []
    //             file2 = [pickFileList[i]['File']];
    //             console.log("file*****", file2)
    //             if (file2[0]['size'] >= 5 * 1024 * 1024) {
    //                 console.log("`1`*****")

    //                 toast.error('File size more than 5mb are not allow.', {
    //                     theme: 'colored',
    //                     autoClose: 3000,
    //                     hideProgressBar: true,
    //                 })
    //             } else {
    //                 console.log("`2`*****")

    //                 var image = '';
    //                 var docList = [];
    //                 if (file2[0]['name'].toString().toLowerCase().includes('.pdf') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.jpeg') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.png') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.jpg') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.doc') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.docx') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.xls') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.xlsx') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.ppt') ||
    //                     file2[0]['name'].toString().toLowerCase().includes('.pptx')
    //                 ) {
    //                     console.log("`3`*****")

    //                     if (file2[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
    //                         file2[0]['name'].toString().toLowerCase().trim().includes('.png') ||
    //                         file2[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
    //                     ) {
    //                         image = await this.resizeFile(file2[0]);
    //                         docFilesList.push({ FileName: file2[0]['name'], File: image, actioId: 1 })
    //                         // pickedFileList.push({ FileName: file[0]['name'], File: image })

    //                         // this.setState({documentFilesList : documentFilesList })

    //                     }
    //                     else if (file2[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
    //                         image = await this.onPdfSelect(file2[0]);
    //                         docFilesList.push({ FileName: file2[0]['name'], File: image, actioId: 1 })
    //                         console.log("12345678", image)
    //                         // pickedFileList.push({ FileName: file[0]['name'], File: image })

    //                         // this.setState({documentFilesList : documentFilesList })

    //                     }
    //                     else if (file2[0]['name'].toString().toLowerCase().includes('.xls') ||
    //                         file2[0]['name'].toString().toLowerCase().includes('.xlsx')) {
    //                         console.log("`3`*****")

    //                         console.log(" 7 xlsx file", file2[0]['name'])
    //                         image = await this.onPdfSelect(file2[0]);
    //                         console.log("099999990  ", image)
    //                         docFilesList.push({ FileName: file2[0]['name'], File: image, actioId: 1 })
    //                         // pickedFileList.push({ FileName: file[0]['name'], File: image })

    //                         // this.setState({documentFilesList : documentFilesList })

    //                     }
    //                     this.setState({ documentFilesList: docFilesList, pickedFileList: pickFileList })
    //                     console.log(" documentFilesList*********", docFilesList)
    //                     console.log(" pickedFileList)()()( *********", pickFileList)


    //                 } else {
    //                     toast.error('Only .pdf , .jpeg, .png, .jpg files are allowed', {
    //                         theme: 'colored',
    //                         autoClose: 3000,
    //                         hideProgressBar: true,
    //                     })
    //                 }
    //             }
    //         }
    //     }


    // }

    // fileToBase64 = (filename, filepath) => {
    //     return new Promise(resolve => {
    //       var file = new File([filename], filepath);
    //       var reader = new FileReader();
    //       // Read file content on file loaded event
    //       reader.onload = function(event) {
    //         resolve(event.target.result);
    //       };

    //       // Convert data to base64 
    //       reader.readAsDataURL(file);
    //     });
    //   };

    onFilePick = async (e, file) => {
        e.preventDefault()
        console.log('actual file is : ', file);
        const { documentFilesList, pickedFileList } = this.state
        console.log('actual file length : ', pickedFileList.length);
        console.log('actual file length : ', [...pickedFileList].length);
        console.log('actual file length : ', pickedFileList.length <= 10);
        console.log('actual documentFilesList file length : ', documentFilesList.length <= 10);
        const maxFiles = 10; // Maximum number of files allowed

        // Check if the total number of files (pickedFileList + new files) exceeds the limit
        if (pickedFileList.length + file.length > maxFiles) {
            toast.error(`You can only upload up to ${maxFiles} files.`, {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            });
            // Clear the file input field
            if (this.fileInputRef.current) {
                this.fileInputRef.current.value = ''; // Clear the input value
            }

            return;
        }


        var pickFileList = [...pickedFileList]
        var docFilesList = [...documentFilesList]
        console.log('actual file pickFileList : ', pickFileList);
        console.log('actual file docFilesList : ', docFilesList);
        for (let i = 0; i < file['length']; i++) {
            if (file[i]['size'] >= 5 * 1024 * 1024) {
                toast.error(`File size more than 5mb are not allow.`, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                // Clear the file input field
                if (this.fileInputRef.current) {
                    this.fileInputRef.current.value = ''; // Clear the input value
                }

                return;
            }
            else {
                var fileMap = {
                    'File': file[i],
                    'name': file[i]['name'],
                    'ExpDocumentID': null,
                    'actionId': 1,
                    // 'id':i
                }
                if (file[i]['name'] != null) {
                    pickFileList.push(fileMap)
                }
            }
        }
        // this.setState({ pickedFileList: pickFileList })
        console.log("pickedFileList 9090", pickFileList)
        for (let i = 0; i < pickFileList.length; i++) {
            console.log("0909 pickedFileList[i]", pickFileList[i])
            if (pickFileList[i]['actionId'] == 1 && pickFileList[i]['ExpDocumentID'] == null) {
                var file2 = []
                file2 = [pickFileList[i]['File']];
                console.log("file*****", file2)
                if (file2[0]['size'] >= 5 * 1024 * 1024) {
                    console.log("`1`*****")

                    toast.error('File size more than 5mb are not allow.', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })

                    // Clear the file input field
                    if (this.fileInputRef.current) {
                        this.fileInputRef.current.value = ''; // Clear the input value
                    }

                    return;

                } else {
                    console.log("`2`*****")

                    var image = '';
                    var docList = [];
                    if (file2[0]['name'].toString().toLowerCase().includes('.pdf') ||
                        file2[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                        file2[0]['name'].toString().toLowerCase().includes('.png') ||
                        file2[0]['name'].toString().toLowerCase().includes('.jpg')
                        // ||
                        // file2[0]['name'].toString().toLowerCase().includes('.doc') ||
                        // file2[0]['name'].toString().toLowerCase().includes('.docx') ||
                        // file2[0]['name'].toString().toLowerCase().includes('.xls') ||
                        // file2[0]['name'].toString().toLowerCase().includes('.xlsx') ||
                        // file2[0]['name'].toString().toLowerCase().includes('.ppt') ||
                        // file2[0]['name'].toString().toLowerCase().includes('.pptx')
                    ) {
                        console.log("`3`*****")

                        if (file2[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                            file2[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                            file2[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                        ) {
                            image = await this.resizeFile(file2[0]);
                            pickFileList.forEach(({ name: FileName, image }) => {
                                if (!docFilesList.some(item => item.FileName == pickFileList[i]['name'])) {
                                    docFilesList.push({ FileName: pickFileList[i]['name'], File: image, actioId: 1 });
                                }
                            });
                            pickFileList.forEach(({ name: FileName, image }) => {
                                if (!pickedFileList.some(item => item.FileName == pickFileList[i]['name'])) {
                                    pickedFileList.push({ FileName: pickFileList[i]['name'], File: image });
                                }
                            });
                            // docFilesList.push({ FileName: pickFileList[i]['name'], File: image, actioId: 1 })
                            // pickedFileList.push({ FileName: pickFileList[i]['name'], File: image })

                        }
                        else {
                            image = await this.onPdfSelect(file2[0]);

                            pickFileList.forEach(({ name: FileName, image }) => {
                                if (!docFilesList.some(item => item.FileName == pickFileList[i]['name'])) {
                                    docFilesList.push({ FileName: pickFileList[i]['name'], File: image, actioId: 1 });
                                }
                            });
                            pickFileList.forEach(({ name: FileName, image }) => {
                                if (!pickedFileList.some(item => item.FileName == pickFileList[i]['name'])) {
                                    pickedFileList.push({ FileName: pickFileList[i]['name'], File: image });
                                }
                            });

                            // docFilesList.push({ FileName: pickFileList[i]['name'], File: image, actioId: 1 })
                            // pickedFileList.push({ FileName: pickFileList[i]['name'], File: image })

                        }
                        // , pickedFileList: pickFileList
                        this.setState({ documentFilesList: docFilesList })
                        console.log(" docFilesList*********", docFilesList)

                        // console.log(" pickedFileList)()()12 *********", pickedFileList)


                    } else {
                        toast.error('Only .pdf , .jpeg, .png, .jpg files are allowed', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        // Clear the file input field
                        if (this.fileInputRef.current) {
                            this.fileInputRef.current.value = ''; // Clear the input value
                        }


                    }
                }
            }
        }

        console.log("documentFilesList 12*********", documentFilesList)


    }

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);

        });

    render() {
        const { isAccessToView, isOpenDeleteFile, expDocumentDeleteId2, expDocumentDeleteId, is_add, is_update, is_Expenseupdate, isOpen, isLoading, allowedFileTypes,
            isActive,
            transactionForId, transactionForList, finYearId, engagementId, clientNameId, clientNameList,
            engagementList, expenseTypeId, expenseTypeList, employeeId, taskId,
            taskList, description, date, amount, remark, rejectionReason, employeeList,
            financialYearList, isNeedSupporting, isPaidByCreditCard, documentFilesList, pickedFileList, ExpenseStatus, expenseDisable, navigateTimesheetAdd, isClientDisable, timesheetDisable, startDate, endDate, is_DateFlag, disableLastDateTimesheetFill } = this.state
        // console.log("098*090", pickedFileList);

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    {/* partial:partials/_navbar.html */}
                    {/* partial */}
                    <div className="container-fluid page-body-wrapper">
                        {/* partial:partials/_settings-panel.html */}
                        <Drawer />
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li>
                                            <a onClick={this.handleCancel}>Expense </a>
                                        </li>
                                        <li>
                                            <a>{this.state.is_add ? 'New' : 'Update'}</a>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                <div className="user-status">
                                    {
                                        is_update ? <p>
                                            <strong className={ExpenseStatus
                                                === 'Entered' || 'Sent for Approval' || 'Approved' || 'Rejected' || 'Deleted' ? 'greena' : ExpenseStatus == " " ? null : 'redi'}> {ExpenseStatus
                                                }</strong>
                                        </p> : <></>
                                    }
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div
                                        className=""
                                        id="myTabContent"

                                    >
                                        <div className="row">
                                            <style
                                                type="text/css"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                                }}
                                            />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className="d-flex flex-wrap row ">
                                                            <div className="form-group col-md-3 ">
                                                                <label>
                                                                    Transaction For
                                                                    <span className="required1">*</span>
                                                                </label>
                                                                <select
                                                                    //  className={is_update || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"} 
                                                                    style={{ width: "100%" }}
                                                                    className={"js-example-basic-single"}

                                                                    // disabled={is_update || navigateTimesheetAdd}
                                                                    disabled={navigateTimesheetAdd || expenseDisable}

                                                                    value={transactionForId}
                                                                    onChange={e => this.onSelectTransactionForId(e)}>
                                                                    <option value={''}>Select</option>
                                                                    {transactionForList.length > 0 ? transactionForList.map(item => (
                                                                        <option
                                                                            key={item.TransactionForID}
                                                                            value={item.TransactionForID}>
                                                                            {item.TransactionFor}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-md-3 mt-1">
                                                                <label>
                                                                    Employee
                                                                    {/* <span className="required1">*</span> */}
                                                                </label>
                                                                <select
                                                                    className={"js-example-basic-single bg-secondary"}
                                                                    style={{ width: "100%" }}
                                                                    disabled={true}
                                                                    value={employeeId}
                                                                    onChange={e => this.setState({ employeeId: e.target.value })}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {employeeList.length > 0 ? employeeList.map(item => (
                                                                        <option
                                                                            key={item.emmnID}
                                                                            value={item.emmnID}>
                                                                            {item.empsFirstName}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Client
                                                                    <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                </label>
                                                                <select
                                                                    className={isClientDisable || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"}
                                                                    disabled={navigateTimesheetAdd || expenseDisable}
                                                                    // className={is_update || isClientDisable || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"}
                                                                    style={{ width: "100%" }}
                                                                    value={clientNameId}
                                                                    // disabled={is_update || isClientDisable || navigateTimesheetAdd}
                                                                    onChange={e => this.onSelectClient(e)}
                                                                >
                                                                    <option value=''>Select</option>
                                                                    {clientNameList.length > 0 ? clientNameList.map(item => (
                                                                        <option
                                                                            key={item.clnnID}
                                                                            value={item.clnnID}>
                                                                            {item.clnsClientFName}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                        </div>
                                                        {console.log("1-------------->", isClientDisable, navigateTimesheetAdd, expenseDisable)}
                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Financial Year
                                                                    <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                </label>
                                                                <select
                                                                    className={isClientDisable || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"}
                                                                    // className={is_update || isClientDisable || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"}
                                                                    // disabled={is_update || isClientDisable || navigateTimesheetAdd}

                                                                    style={{ width: "100%" }}
                                                                    disabled={isClientDisable || navigateTimesheetAdd || expenseDisable}
                                                                    value={finYearId} onChange={e => this.onSelectFinYear(e)}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {financialYearList.length > 0 ? financialYearList.map(item => (
                                                                        <option
                                                                            key={item.FinYearID}
                                                                            value={item.FinYearID}>
                                                                            {item.FinYear}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Engagement <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                </label>
                                                                <select
                                                                    className={isClientDisable || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"}
                                                                    disabled={isClientDisable || navigateTimesheetAdd || expenseDisable}
                                                                    // className={is_update || isClientDisable || navigateTimesheetAdd ? 'bg-secondary' : "js-example-basic-single"}
                                                                    style={{ width: "100%" }}
                                                                    // disabled={is_update || isClientDisable || navigateTimesheetAdd}
                                                                    value={engagementId}
                                                                    onChange={e => this.onSelectEngagementId(e)}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {engagementList.length > 0 ? engagementList.map(item => (
                                                                        <option
                                                                            key={item.EngagementID}
                                                                            value={item.EngagementID}>
                                                                            {item.MainEngagement}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Expense Type<span className="required1">*</span>
                                                                </label>
                                                                <select
                                                                    className={expenseDisable ? "js-example-basic-single bg-secondary" : "js-example-basic-single"}
                                                                    disabled={expenseDisable}
                                                                    style={{ width: "100%" }}
                                                                    value={expenseTypeId}
                                                                    onChange={e => this.setState({ expenseTypeId: e.target.value })}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {expenseTypeList.length > 0 ? expenseTypeList.map(item => (
                                                                        <option
                                                                            key={item.ExpenseHeadID}
                                                                            value={item.ExpenseHeadID}>
                                                                            {item.ExpenseType}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>

                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Date<span className="required1">*</span>
                                                                </label>
                                                                {console.log(" ************** ", transactionForId)}
                                                                <div className="form-group d-flex">
                                                                    <DatePicker
                                                                        className={timesheetDisable || is_update || (transactionForId != '2' ? engagementId == '' || is_DateFlag : '') || navigateTimesheetAdd ? "js-example-basic-single bg-secondary w-100" : "js-example-basic-single w-100"}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        selected={this.state.date}
                                                                        disabled={timesheetDisable || is_update || (transactionForId != '2' ? engagementId == '' || is_DateFlag : '') || navigateTimesheetAdd}
                                                                        // maxDate={Date.now()}
                                                                        // maxDate={transactionForId != '2' ? endDate : ''}
                                                                        // minDate={transactionForId != '2' ? startDate : ''}
                                                                        maxDate={this.state.endDate}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        onChange={(date) => { this.onSelectDate(date) }}
                                                                        placeholderText="Date"
                                                                        customInput={<ExampleCustomInput />}
                                                                        dayClassName={() => "example-datepicker-day-class"}
                                                                        popperClassName="example-datepicker-class"
                                                                    />
                                                                    {/* <FaCalendarAlt style={{
                                                                    flex: '0 0 19%',
                                                                    maxWidth: '19%',
                                                                    color: 'grey',
                                                                    marginTop: '7px',
                                                                    marginLeft: '-3rem',
                                                                    zIndex: '0'
                                                                }} /> */}
                                                                </div>
                                                            </div>


                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Amount<span className="required1">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={expenseDisable ? "js-example-basic-single bg-secondary w-100" : "js-example-basic-single form-control"}
                                                                    disabled={expenseDisable}
                                                                    pattern="[0-9]"
                                                                    value={amount}
                                                                    onChange={(e) => this.setState({ amount: e.target.value.replace(/\D/, '') })}
                                                                    required
                                                                    maxLength={18}
                                                                />

                                                            </div>

                                                        </div>


                                                        {/* <div className="d-flex flex-wrap row mt-2"> */}
                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-6">
                                                                <label>
                                                                    Description
                                                                    <span className="required1">*</span>
                                                                </label>
                                                                <textarea
                                                                    className={expenseDisable ? "js-example-basic-single bg-secondary w-100 h-auto border-0 rounded-1" : "js-example-basic-single form-control h-auto"}
                                                                    disabled={expenseDisable}
                                                                    type="text"
                                                                    maxLength={500}
                                                                    value={description}
                                                                    onChange={(e) => this.setState({ description: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-6">
                                                                <label>Remark</label>
                                                                <div className="form-group">
                                                                    <textarea
                                                                        maxLength={500}
                                                                        className={expenseDisable ? "js-example-basic-single bg-secondary w-100 h-auto border-0 rounded-1" : "js-example-basic-single form-control h-auto"}
                                                                        disabled={expenseDisable}
                                                                        name="remark"
                                                                        rows={2}
                                                                        cols={50}
                                                                        // defaultValue={this.state.remark}
                                                                        value={remark}
                                                                        onChange={e => this.setState({ remark: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                        {ExpenseStatus === 'Rejected' ?
                                                            <div className="d-flex flex-wrap row mt-2">
                                                                <div className="form-group col-md-6">
                                                                    <label>Rejection Reason  </label>
                                                                    <div className="form-group">
                                                                        <textarea
                                                                            className={"js-example-basic-single bg-secondary w-100 h-auto border-0 rounded-1"}
                                                                            disabled={true}
                                                                            name="remark"
                                                                            maxLength={250}
                                                                            rows={2}
                                                                            cols={50}
                                                                            style={{ border: "1px solid #dee2e6" }}
                                                                            value={rejectionReason}
                                                                            onChange={e => this.setState({ rejectionReason: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                        <div className=" row m-0 mt-1">
                                                            {/* <div className="form-check col d-flex">
                                                            <input type="checkbox"
                                                                checked={isPaidByCreditCard}
                                                                selected={isPaidByCreditCard}
                                                                value={isPaidByCreditCard}
                                                                onChange={e => this.setState({ isPaidByCreditCard: !isPaidByCreditCard })}
                                                            />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                Paid By Credit Card
                                                            </label>
                                                        </div> */}
                                                            <div className="p-0 col d-flex form-group">
                                                                <label className=" d-flex " for="flexCheckChecked">
                                                                    <input type="checkbox"
                                                                        className='h-auto mr-1'
                                                                        disabled={expenseDisable}
                                                                        checked={isNeedSupporting}
                                                                        selected={isNeedSupporting}
                                                                        value={isNeedSupporting}
                                                                        onChange={e => this.setState({ isNeedSupporting: !isNeedSupporting })} /> Need Supporting
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            isNeedSupporting ?
                                                                <><div className="form-group col-5 mt-2">
                                                                    <div className='row mb-2'>
                                                                        <div className='col-sm-12 d-flex' style={{ position: 'relative' }}>
                                                                            <span className='mx-2 pt-2'>Document</span>
                                                                            <i class="fa fa-info-circle file_info m-0 mx-3 mt-2 info-icon"
                                                                                title={`Allowed File types ${allowedFileTypes.map((type) => type.toUpperCase().replace('.', '')).join(', ')} with maximum size 5 MB`} ></i>
                                                                            <div className="input-group js-example-basic-single">
                                                                                <input type="file"
                                                                                    style={{ lineHeight: '5px' }}
                                                                                    className="form-control"
                                                                                    disabled={expenseDisable}
                                                                                    id="inputGroupFile"
                                                                                    ref={this.fileInputRef} // Attach ref here
                                                                                    // value={documentFilesList}
                                                                                    onChange={(e) => this.onFilePick(e, e.target.files)}
                                                                                    accept={".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"} multiple />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='col-sm-3 text-start'>
                                                                            <i class="fa fa-info-circle file_info m-0"
                                                                                title={`Allowed File types ${allowedFileTypes.map((type) => type.toUpperCase().replace('.', '')).join(', ')} with maximum size 5 MB`} ></i>
                                                                        </div> */}
                                                                        {/* <div className='col-sm-6 p-0'>
                                                                            <div className="input-group js-example-basic-single">
                                                                                <input type="file"
                                                                                    style={{ lineHeight: '5px' }}
                                                                                    className="form-control"
                                                                                    disabled={expenseDisable}
                                                                                    id="inputGroupFile"
                                                                                    ref={this.fileInputRef} // Attach ref here
                                                                                    // value={documentFilesList}
                                                                                    onChange={(e) => this.onFilePick(e, e.target.files)}
                                                                                    accept={".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"} multiple />
                                                                            </div>

                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                    <div className='col-md-5 bg-secondary'>
                                                                        {
                                                                            documentFilesList.length > 0 && documentFilesList.map((data, index) => (
                                                                                <ul class="list-group d-flex justify-content-around">
                                                                                    {
                                                                                        < li class={expenseDisable ? "list-group-item p-2 js-example-basic-single" : "list-group-item p-2 mb-1"} key={index}>{data.FileName}
                                                                                            {
                                                                                                !expenseDisable ?
                                                                                                    <span className="text-danger"><i className="fa fa-remove mx-2 mt-1 float-right img-cancel-icon"
                                                                                                        // onClick={(e) => this.expenseDeleteDocumentId(e, data.ExpDocumentID, index)}
                                                                                                        onClick={(e) => this.toggleModalDeleteFile(e, data.ExpDocumentID, index)}

                                                                                                    /></span> :
                                                                                                    <></>
                                                                                            }
                                                                                            {/* {console.log("documentFilesList", data)} */}
                                                                                            {is_update ?
                                                                                                <i
                                                                                                    type="button"
                                                                                                    className="fa fa-download  float-right"
                                                                                                    onClick={() => this.handleDownload(data.File, data.FileName)}
                                                                                                >
                                                                                                </i>
                                                                                                : <></>}
                                                                                        </li>
                                                                                    }
                                                                                </ul>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </> : <></>
                                                        }

                                                        <div style={{ height: 50 }} />
                                                        <div className="row">
                                                            <div className="footer-btn">
                                                                <div className="col-md-3">
                                                                    <div
                                                                        className="btn-group"
                                                                        role="group"
                                                                        aria-label="Basic example"
                                                                    >

                                                                        {
                                                                            (is_add && is_Expenseupdate) || is_update ?
                                                                                <>
                                                                                    {
                                                                                        !expenseDisable && getModuleUpdateAccess(Expense) ?
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-update footer-btn-inner mr-1"
                                                                                                onClick={e => this.handleUpdate(e)}
                                                                                                disabled={isLoading}
                                                                                            > Update </button>
                                                                                            : <></>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {getModuleWriteAccess(Expense) && <button
                                                                                        type="button"
                                                                                        className="btn btn-save footer-btn-inner mr-1"
                                                                                        onClick={e => this.onSave(e)}
                                                                                        disabled={isLoading}
                                                                                    > Save </button>}
                                                                                    {getModuleWriteAccess(Expense) && <button
                                                                                        type="button"
                                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                                        onClick={e => this.handleReset()}
                                                                                    >
                                                                                        Reset
                                                                                    </button>}

                                                                                </>



                                                                        }
                                                                        {/* {
                                                                        is_update? */}
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-cancel footer-btn-inner"
                                                                            onClick={this.handleCancel}
                                                                        >
                                                                            Cancel
                                                                        </button>

                                                                        {/* :<></>
                                                                    } */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* end page */}
                            </div>
                            {/* page-body-wrapper ends */}
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                    </div>
                </div >
                <div className='' style={{ height: '50%' }}></div>
                <ToastContainer />
                <Modal
                    isOpen={isOpen}
                    onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Email</h4>
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you want to send the email?</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot" >
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.onSaveAndMail(e, true)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={(e) => this.onSaveAndMail(e, false)}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpenDeleteFile} onRequestClose={() => this.toggleModalDeleteFile('')}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm File Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleModalDeleteFile('')} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this file</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.expenseDeleteDocumentId(e, expDocumentDeleteId, expDocumentDeleteId2)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={() => this.toggleModalDeleteFile('')}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </>
        );
    }
}

export default withRouter(connect(null, {
    addExpense_action, updateExpense_action, expenseDetails_action, getTimesheetId_action,
    fill_action, approvalExpense_action, deleteExpenseDocument_action, LastTimesheetFetch_action
})(ExpenseAU));